<template>
  <div>
    <v-row justify="center">
      <v-col cols="8">
      <v-tabs
        v-model="tab"
        centered
        grow
      >
        <v-tab>
          Users Per Day
          <v-icon class="ml-2">mdi-account</v-icon>
        </v-tab>
        <v-tab>
          Users Per Hour
          <v-icon class="ml-2">mdi-account</v-icon>
        </v-tab>
        <v-tab>
          User Locales
          <v-icon class="ml-2">mdi-earth</v-icon>
        </v-tab>
        <v-tab>
          User Acquisition
          <v-icon class="ml-2">mdi-routes</v-icon>
        </v-tab>
      </v-tabs>
      </v-col>
      <v-col cols="4">
      <v-menu>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...menu }"
                    color="primary"
                    medium
                    class="mt-2 float-left"
                  >
                    Date Range
                    <v-icon right color="white">mdi-chevron-down</v-icon>
                  </v-btn>
              </template>
              <span>Select a date range</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in dateOptions"
              :key="index"
              @click="getPreSetUsers(item.value)"
            >
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-if="dateFilter === 5">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="mt-2"
                medium
              >
                Select a Range
                <v-icon right>mdi-calendar-range</v-icon>
              </v-btn>
            </template>
            <v-date-picker v-model="dates" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="dark" @click="menu = false">Cancel</v-btn>
              <v-btn :disabled="dates.length <= 1" text color="dark" @click="getCustomDateUsers">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-tabs-items v-if="chartLoaded" v-model="tab">
      <v-tab-item>
        <v-row v-if="chartLoaded">
          <v-col cols="12">
            <base-material-card
              color="info"
              icon="mdi-account"
              :title="'Avg Users Per Day - ' + dateRangeText"
            >
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <bar-chart v-if="chartLoaded" :chart-data="usersPerDay" />
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row v-if="chartLoaded">
          <v-col cols="12">
            <base-material-card
              color="info"
              icon="mdi-account"
              :title="'Avg Users Per Hour - ' + dateRangeText"
            >
              <v-row>
                <v-col cols="12">
                  <hour-line v-if="chartLoaded" :chart-data="usersPerHour" />
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row v-if="chartLoaded">
          <v-col cols="12">
            <base-material-card color="info" icon="mdi-earth" :title="'User Locales - ' + dateRangeText">
              <v-row>
                <v-col cols="4">
                  <v-simple-table
                    class="ml-2"
                    height="500px"
                    fixed-header
                  >
                  <thead>
                    <tr>
                      <th class="text-left">
                        Country
                      </th>
                      <th class="text-left">
                        Users
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr
                        v-for="(country, i) in country"
                        :key="i"
                      >
                        <td v-text="country.name" />
                        <td v-text="country.users" />
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col cols="4">
                  <v-simple-table
                    class="ml-2"
                    height="500px"
                    fixed-header
                  >
                  <thead>
                    <tr>
                      <th class="text-left">
                        City
                      </th>
                      <th class="text-left">
                        Users
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr
                        v-for="(city, i) in city"
                        :key="i"
                      >
                        <td v-text="city.name" />
                        <td v-text="city.users" />
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
                <v-col cols="4">
                  <v-simple-table
                    class="ml-2"
                    height="500px"
                    fixed-header
                  >
                  <thead>
                    <tr>
                      <th class="text-left">
                        Language
                      </th>
                      <th class="text-left">
                        Users
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr
                        v-for="(lang, i) in language"
                        :key="i"
                      >
                        <td v-text="lang.name" />
                        <td v-text="lang.users" />
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-row v-if="chartLoaded">
          <v-col cols="12">
            <base-material-card color="info" icon="mdi-routes" :title="'User Acquisition - ' + dateRangeText">
              <v-row>
                <v-col cols="6" md="6" sm="12">
                  <div v-if="noUserSourceFlag === true">
                    <v-alert border="right" colored-border type="error" elevation="2">
                      <v-row align="center" justify="center">
                        <v-col class="grow text-h3">No metrics found for this range.</v-col>
                      </v-row>
                    </v-alert>
                  </div>
                  <h4>Top referral sources (excl. direct traffic)</h4>
                  <polar-chart :chart-data="userAcquisition" />
                </v-col>
                <v-col cols="6">
                  <v-data-table
                    :headers="sourceHeaders"
                    :items="source"
                    class="elevation-1"
                  ></v-data-table>
                </v-col>
              </v-row>
            </base-material-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular
        v-if="snackColor === 'info'"
        color="white"
        indeterminate
      ></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import getUserMetrics from '@/Services/Analytics/getUsersMetrics'
import HourLine from '@/views/dashboard/charts/HourLine'
import BarChart from '@/views/dashboard/charts/BarChart'
import PolarChart from '@/views/dashboard/charts/PolarChart'
export default {
  name: 'Users',
  components: {
    HourLine,
    BarChart,
    PolarChart
  },
  data: () => {
    return {
      tab: null,
      chartLoaded: false,
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      dates: [],
      menu: false,
      dateFilter: 4,
      dateOptions: [
        { value: 1, text: 'Today' },
        { value: 2, text: 'Yesterday' },
        { value: 3, text: 'Last 7 Days' },
        { value: 4, text: 'Last 28 Days' },
        { value: 5, text: 'Custom Date Range' }
      ],
      users: '',
      userDifference: '',
      userPrevious: '',
      country: [],
      city: [],
      language: [],
      source: [],
      noUserSourceFlag: false,
      userAcquisition: {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: [
            'rgba(0, 200, 255, 0.5)',
            'rgba(155, 89, 182, 0.5)',
            'rgba(0, 100, 255, 0.5)',
            'rgba(255, 0, 0, 0.5)',
            'rgba(255,160,0,0.5)',
            'rgba(200, 50, 255, 0.5)',
            'rgba(247, 202, 24, 0.5)'
          ]
        }]
      },
      sourceHeaders: [
        { text: 'Source', align: 'center', value: 'name' },
        { text: 'Users', align: 'center', value: 'users' }
      ],
      avgUsersPerHour: [],
      avgUsersPerHourPrev: [],
      avgUsersPerDay: [],
      avgUsersPerDayPrev: [],
      usersPerHour: {
        datasets: [
          {
            label: 'Current Period',
            data: [],
            fill: true,
            borderColor: 'rgba(0, 200, 255, 1)',
            backgroundColor: 'rgba(0, 200, 255, 0.5)'
          },
          {
            label: 'Previous Period',
            data: [],
            fill: true,
            borderColor: 'rgba(255,160,0,1)',
            backgroundColor: 'rgba(255,160,0,0.5)'
          }
        ]
      },
      usersPerDay: {
        datasets: [
          {
            label: 'Current Period',
            data: [],
            fill: true,
            borderColor: 'rgba(0, 200, 255, 1)',
            backgroundColor: 'rgba(0, 200, 255, 0.5)'
          },
          {
            label: 'Previous Period',
            data: [],
            fill: true,
            borderColor: 'rgba(255,160,0,1)',
            backgroundColor: 'rgba(255,160,0,0.5)'
          }
        ]
      }
    }
  },
  mounted () {
    this.initialise(4)
  },
  computed: {
    dateRangeText () {
      let dateText
      if (this.dateFilter === 5) {
        dateText = this.dates.join(' ~ ')
      } else {
        this.dateOptions.forEach(element => {
          if (element.value !== 5 && element.value === this.dateFilter) {
            dateText = element.text
          }
        })
      }
      return dateText
    }
  },
  methods: {
    async initialise (filter) {
      this.$emit('status', 'loading')
      this.chartLoaded = false
      let filterObject = {}
      this.dates = []
      this.resetData()
      filterObject = {
        filterType: filter
      }
      await getUserMetrics.getUserMetrics(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.$emit('status', 'finished')
          } else {
            this.$emit('status', 'userError')
          }
        })
    },
    async getPreSetUsers (value) {
      this.dateFilter = value
      this.dates = []
      this.resetData()
      let filterObject = {}
      if (this.dateFilter !== 5) {
        this.loadSnack()
        filterObject = {
          filterType: this.dateFilter
        }
        await getUserMetrics.getUserMetrics(filterObject)
          .then((response) => {
            this.assignResponse(response)
            this.finishSnack()
          })
      }
    },
    async getCustomDateUsers () {
      this.loadSnack()
      this.$refs.menu.save(this.dates)
      this.menu = false
      this.resetData()
      let filterObject = {}
      if (this.dateFilter === 5) {
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          await getUserMetrics.getUserMetrics(filterObject)
            .then((response) => {
              this.assignResponse(response)
              this.finishSnack()
            })
        } else {
          this.errorSnack()
        }
      }
    },
    assignResponse (res) {
      const data = res.data
      this.$set(this, 'users', data.users)
      this.$set(this, 'userDifference', data.userDifference)
      this.$set(this, 'userPrevious', data.userPrevious)
      this.$set(this, 'country', data.country)
      this.$set(this, 'city', data.city)
      this.$set(this, 'language', data.language)
      this.$set(this, 'avgUsersPerHour', data.avgUsersPerHour)
      this.$set(this, 'source', data.acquisitionSource)
      this.source.sort((a, b) => b - a)
      var i
      if (this.source.length >= 6) {
        for (i = 0; i < 6; i++) {
          if (this.source[i].name !== '(direct)') {
            this.userAcquisition.labels.push(this.source[i].name)
            this.userAcquisition.datasets[0].data.push(this.source[i].users)
          }
        }
      } else if (this.source.length <= 5) {
        this.source.forEach(element => {
          if (element.name !== '(direct)') {
            this.userAcquisition.labels.push(element.name)
            this.userAcquisition.datasets[0].data.push(element.users)
          }
        })
      } else {
        this.noUserSourceFlag = true
      }
      this.avgUsersPerHour.forEach(element => {
        this.usersPerHour.labels.push(element.name + ':00')
        this.usersPerHour.datasets[0].data.push(element.users)
      })
      this.$set(this, 'avgUsersPerHourPrev', data.avgUsersPerHourPrevious)
      this.avgUsersPerHourPrev.forEach(element => {
        this.usersPerHour.datasets[1].data.push(element.users)
      })
      this.$set(this, 'avgUsersPerDay', data.avgUsersPerDay)
      this.avgUsersPerDay.forEach(element => {
        switch (element.name) {
          case 'Monday':
            element.sortOrder = 1
            break
          case 'Tuesday':
            element.sortOrder = 2
            break
          case 'Wednesday':
            element.sortOrder = 3
            break
          case 'Thursday':
            element.sortOrder = 4
            break
          case 'Friday':
            element.sortOrder = 5
            break
          case 'Saturday':
            element.sortOrder = 6
            break
          case 'Sunday':
            element.sortOrder = 7
            break
        }
      })
      this.avgUsersPerDay.sort((a, b) => parseFloat(a.sortOrder) - parseFloat(b.sortOrder))
      this.avgUsersPerDay.forEach(element => {
        this.usersPerDay.labels.push(element.name)
        this.usersPerDay.datasets[0].data.push(element.users)
      })
      this.$set(this, 'avgUsersPerDayPrev', data.avgUsersPerDayPrevious)
      this.avgUsersPerDayPrev.forEach(element => {
        this.usersPerDay.datasets[1].data.push(element.users)
      })
      this.chartLoaded = true
    },
    resetData () {
      this.usersPerHour.labels = []
      this.usersPerHour.datasets.forEach(element => {
        element.data = []
      })
      this.usersPerDay.labels = []
      this.usersPerDay.datasets.forEach(element => {
        element.data = []
      })
      this.userAcquisition.labels = []
      this.userAcquisition.datasets.forEach(element => {
        element.data = []
      })
    },
    loadSnack () {
      this.chartLoaded = false
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
    },
    finishSnack () {
      this.snackColor = 'success'
      this.snackText = 'Done'
      this.snackTimeout = 3000
      this.chartLoaded = true
    },
    errorSnack () {
      this.snackColor = 'error'
      this.snackText = 'Error'
      this.snackTimeout = 3000
    }
  }
}
</script>
