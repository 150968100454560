<template>
<div>
  <v-row justify="center">
    <v-col cols="8">
      <v-tabs v-model="tab" centered grow>
        <v-tab>
          Engagements
          <v-icon class="ml-2">mdi-target-account</v-icon>
        </v-tab>
        <v-tab>
          Listings
          <v-icon class="ml-2">mdi-content-paste</v-icon>
        </v-tab>
        <v-tab>
          Videos
          <v-icon class="ml-2">mdi-camera</v-icon>
        </v-tab>
        <v-tab>
          Resources
          <v-icon class="ml-2">mdi-folder</v-icon>
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="4">
      <v-menu>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">

              <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }" color="primary" medium class="mt-2 float-left">
                Date Range
                <v-icon right color="white">mdi-chevron-down</v-icon>
              </v-btn>

            </template>
            <span>Select a date range</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in dateOptions" :key="index" @click="getPreSetBehaviour(item.value)">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div v-if="dateFilter === 5">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on" class="mt-2" medium>
              Select a Range
              <v-icon right>mdi-calendar-range</v-icon>
            </v-btn>
          </template>
          <v-date-picker v-model="dates" scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="dark" @click="menu = false">Cancel</v-btn>
            <v-btn :disabled="dates.length <= 1" text color="dark" @click="getCustomDateBehaviour">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <v-tabs-items v-if="chartLoaded" v-model="tab">
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>User Engagement - {{dateRangeText}}</v-card-title>
                <line-chart v-if="chartLoaded && (dateFilter !== 1 && dateFilter !== 2)" :chart-data="engagementSingleData" />
                <bar-chart v-if="chartLoaded && (dateFilter === 1 || dateFilter === 2)" :chart-data="engagementSingleData" />
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <v-card>
                <v-card-title>User Frequency - {{dateRangeText}}</v-card-title>
                <br>
                <v-card-subtitle>Number of Users versus the number of Returning Sessions they had.</v-card-subtitle>
                <frequency-bar v-if="chartLoaded" :chart-data="frequencyData" />
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12">
              <v-card>
                <v-card-title>User Recency - {{dateRangeText}}</v-card-title>
                <br>
                <v-card-subtitle>Number of days in between user sessions</v-card-subtitle>
                <recency-bar v-if="chartLoaded" :chart-data="recencyData" />
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-alert v-model="noListingsAlert" v-if="noListings" border="right" colored-border type="error" elevation="2">
                <v-row align="center" justify="center">
                  <v-col class="grow text-h3">No listings found. Select another date range, or try again later</v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="!noListings">
            <v-col cols="12">
              <!-- <line-chart v-if="chartLoaded" :chart-data="listingsViewsData" /> -->
              <v-card>
                <v-card-title>Listing Views - {{dateRangeText}}</v-card-title>
                <line-chart v-if="chartLoaded && (dateFilter !== 1 && dateFilter !== 2)" :chart-data="listingsViewsData" />
                <bar-chart v-if="chartLoaded && (dateFilter === 1 || dateFilter === 2)" :chart-data="listingsViewsData" />
                <v-card-text>Total Listings viewed per day</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!noListings">
            <v-col cols="12">
              <v-card>
                <v-card-title>All Listings</v-card-title>
                <div class="text-center pt-2">
                  <v-btn
                    color="primary"
                    class="mr-2"
                    @click="toggleListingOrder"
                  >
                    Sort By Category
                    <v-icon v-if="listingSortOrder === true" small class="mr-2">mdi-arrow-up</v-icon>
                    <v-icon v-if="listingSortOrder === false" small class="mr-2">mdi-arrow-down</v-icon>
                  </v-btn>
                </div>
                <v-data-table :loading="listingLoading" :headers="headers" :items="listings" :disable-pagination="true" sort-by="number" hide-default-footer>
                  <template v-slot:item.view="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a target="_blank" :href="item.url" style="text-decoration: none;">
                          <v-icon small class="mr-2" v-bind="attrs" v-on="on" color="teal lighten-1">
                            mdi-eye
                          </v-icon>
                        </a>
                      </template>
                      <span>View Listing on Frog.net</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2">
                  <v-btn small rounded :disabled="prevListingCursor === undefined || prevListingCursor === ''" color="blue darken-1" v-on:click="previousListingPage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn small rounded :disabled="nextListingCursor === undefined || nextListingCursor === ''" color="blue darken-1" v-on:click="nextListingPage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!noListings">
            <v-col cols="12">
              <v-card class="d-print-block">
                <v-card-title>Listing Category Insights - {{dateRangeText}}</v-card-title>
                <v-row>
                  <v-col cols="6" md="6" sm="12">
                    <v-card flat>
                      <v-card-title>Current Period</v-card-title>
                      <percent-doughnut v-if="chartLoaded" :chart-data="listingsCatData" />
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="6" sm="12">
                    <v-card flat>
                      <v-card-title>Previous Period</v-card-title>
                      <percent-doughnut v-if="chartLoaded" :chart-data="listingsCatPrevData" />
                    </v-card>
                  </v-col>
                </v-row>
                <v-card-subtitle>Current date period vs the previous date period. Views per Category associated with listings.</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-alert v-model="noVideosAlert" v-if="noVideos" border="right" colored-border type="error" elevation="2">
                <v-row align="center" justify="center">
                  <v-col class="grow text-h3">No videos found. Select another date range, or try again later</v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="!noVideos">
            <v-col cols="12">
              <v-card>
                <v-card-title>Video Views - {{dateRangeText}}</v-card-title>
                <line-chart v-if="chartLoaded && (dateFilter !== 1 && dateFilter !== 2)" :chart-data="videosViewsData" />
                <bar-chart v-if="chartLoaded && (dateFilter === 1 || dateFilter === 2)" :chart-data="videosViewsData" />
                <v-card-text>Total videos viewed per day</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!noVideos">
            <v-col cols="12">
              <v-card>
                <v-card-title>All Videos</v-card-title>
                <div class="text-center pt-2">
                  <v-btn
                    color="primary"
                    class="mr-2"
                    @click="toggleVideoOrder"
                  >
                    Sort By Category
                    <v-icon v-if="videoSortOrder === true" small class="mr-2">mdi-arrow-up</v-icon>
                    <v-icon v-if="videoSortOrder === false" small class="mr-2">mdi-arrow-down</v-icon>
                  </v-btn>
                </div>
                <v-data-table :loading="videoLoading" :headers="headers" :items="videos" :disable-pagination="true" hide-default-footer>
                  <template v-slot:item.view="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <a target="_blank" :href="item.url" style="text-decoration: none;">
                          <v-icon small class="mr-2" v-bind="attrs" v-on="on" color="teal lighten-1">
                            mdi-eye
                          </v-icon>
                        </a>
                      </template>
                      <span>View Listing on Frog.net</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2">
                  <v-btn small rounded :disabled="prevVideoCursor === undefined || prevVideoCursor === ''" color="blue darken-1" v-on:click="previousVideoPage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn small rounded :disabled="nextVideoCursor === undefined || nextVideoCursor === ''" color="blue darken-1" v-on:click="nextVideoPage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!noVideos">
            <v-col cols="12">
              <v-card>
                <v-card-title>Video Category Insights - {{dateRangeText}}</v-card-title>
                <v-row>
                  <v-col cols="6" md="6" sm="12">
                    <v-card flat>
                      <v-card-title>Current Period</v-card-title>
                      <percent-doughnut v-if="chartLoaded" :chart-data="videosCatData" />
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="6" sm="12">
                    <v-card flat>
                      <v-card-title>Previous Period</v-card-title>
                      <percent-doughnut v-if="chartLoaded" :chart-data="videosCatPrevData" />
                    </v-card>
                  </v-col>
                </v-row>
                <v-card-subtitle>Current date period vs the previous date period. Views per Category associated with videos.</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <v-alert v-model="noResourcesAlert" v-if="noResources" border="right" colored-border type="error" elevation="2">
                <v-row align="center" justify="center">
                  <v-col class="grow text-h3">No resources found. Select another date range, or try again later</v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="!noResources">
            <v-col cols="12">
              <v-card>
                <v-card-title>Resource Views - {{dateRangeText}}</v-card-title>
                <line-chart v-if="chartLoaded && (dateFilter !== 1 && dateFilter !== 2)" :chart-data="resourceViewsData" />
                <bar-chart v-if="chartLoaded && (dateFilter === 1 || dateFilter === 2)" :chart-data="resourceViewsData" />
                <v-card-text>Total resources viewed per day</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!noResources">
            <v-col cols="12">
              <v-card>
                <v-card-title>All Resources</v-card-title>
                <div class="text-center pt-2">
                  <v-btn
                    color="primary"
                    class="mr-2"
                    @click="toggleResourceOrder"
                  >
                    Sort By Category
                    <v-icon v-if="resourceSortOrder === true" small class="mr-2">mdi-arrow-up</v-icon>
                    <v-icon v-if="resourceSortOrder === false" small class="mr-2">mdi-arrow-down</v-icon>
                  </v-btn>
                </div>
                <v-data-table :loading="resourceLoading" :headers="resourceHeaders" :items="resources" :disable-pagination="true" hide-default-footer />
                <div class="text-center pt-2">
                  <v-btn small rounded :disabled="prevResourceCursor === undefined || prevResourceCursor === ''" color="blue darken-1" v-on:click="previousResourcePage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn small rounded :disabled="nextResourceCursor === undefined || nextResourceCursor === ''" color="blue darken-1" v-on:click="nextResourcePage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!noResources">
            <v-col cols="12">
              <v-card>
                <v-card-title>Resource Category Insights - {{dateRangeText}}</v-card-title>
                <v-row>
                  <v-col cols="6" md="6" sm="12">
                    <v-card flat>
                      <v-card-title>Current Period</v-card-title>
                      <percent-doughnut v-if="chartLoaded" :chart-data="resourceCatData" />
                    </v-card>
                  </v-col>
                  <v-col cols="6" md="6" sm="12">
                    <v-card flat>
                      <v-card-title>Previous Period</v-card-title>
                      <percent-doughnut v-if="chartLoaded" :chart-data="resourceCatPrevData" />
                    </v-card>
                  </v-col>
                </v-row>
                <v-card-subtitle>Current date period vs the previous date period. Views per Category associated with resources.</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
  <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
    <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
    {{ snackText }}
  </v-snackbar>
</div>
</template>

<script>
import getBehaviour from '@/Services/Analytics/getBehaviourMetrics'
import LineChart from '@/views/dashboard/charts/LineChart'
import BarChart from '@/views/dashboard/charts/BarChart'
import RecencyBar from '@/views/dashboard/charts/RecencyBar'
import FrequencyBar from '@/views/dashboard/charts/FrequencyBar'
import PercentDoughnut from '@/views/dashboard/charts/PercentDoughnut'
export default {
  name: 'Behaviour',
  components: {
    LineChart,
    BarChart,
    RecencyBar,
    FrequencyBar,
    PercentDoughnut
  },
  data: () => {
    return {
      listingSortOrder: false,
      videoSortOrder: false,
      resourceSortOrder: false,
      listingLoading: false,
      videoLoading: false,
      resourceLoading: false,
      headers: [{
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'uniqueID'
      },
      {
        text: 'View',
        value: 'view',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category',
        sortable: false
      },
      {
        text: 'Number of Views',
        value: 'numberofViews',
        sortable: false
      },
      {
        text: 'Published Date',
        value: 'publishedDate',
        sortable: false
      }
      ],
      resourceHeaders: [{
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'uniqueID'
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category',
        sortable: false
      },
      {
        text: 'Number of Views',
        value: 'numberofViews',
        sortable: false
      },
      {
        text: 'Published Date',
        value: 'publishedDate',
        sortable: false
      }
      ],
      listings: [],
      videos: [],
      resources: [],
      nextListingCursor: null,
      prevListingCursor: null,
      nextVideoCursor: null,
      prevVideoCursor: null,
      nextResourceCursor: null,
      prevResourceCursor: null,
      rowsPerPage: 10,
      noListingsAlert: false,
      noListings: false,
      noVideosAlert: false,
      noVideos: false,
      noResourcesAlert: false,
      noResources: false,
      responseCode: null,
      tab: null,
      chartLoaded: false,
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      dates: [],
      menu: false,
      dateFilter: 4,
      dateOptions: [{
        value: 1,
        text: 'Today'
      },
      {
        value: 2,
        text: 'Yesterday'
      },
      {
        value: 3,
        text: 'Last 7 Days'
      },
      {
        value: 4,
        text: 'Last 28 Days'
      },
      {
        value: 5,
        text: 'Custom Date Range'
      }
      ],
      articleViewCat: [],
      articleViewCatPrev: [],
      engagement: {
        engagements: [],
        users: []
      },
      frequency: [],
      frequencyPrev: [],
      frequencyData: {
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9-14', '15-25', '26-50', '51-100', '201+'],
        datasets: [{
          label: 'Users - Current Period',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Users - Previous Period',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }]
      },
      recency: [],
      recencyPrev: [],
      recencyData: {
        labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8-14', '15-30', '31-60', '60-120'],
        datasets: [{
          label: 'Users - Current Period',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Users - Previous Period',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }]
      },
      engagementSingleData: {
        labels: [],
        datasets: [{
          label: 'Engagements - Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Users - Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        },
        {
          label: 'Engagements - Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(56, 235, 140, 1)',
          backgroundColor: 'rgba(56, 235, 140, 0.5)'
        },
        {
          label: 'Users - Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(235, 105, 91, 1)',
          backgroundColor: 'rgba(235, 105, 91, 0.5)'
        }
        ]
      },
      engagementPrev: {
        engagements: [],
        users: []
      },
      listingView: [],
      listingViewPrev: [],
      listingViewCat: [],
      listingViewCatPrev: [],
      videoView: [],
      videoViewPrev: [],
      videoViewCat: [],
      videoViewCatPrev: [],
      resourceView: [],
      resourceViewPrev: [],
      resourceViewCat: [],
      resourceViewCatPrev: [],
      listingsViewsData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      listingsCatData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)'
          ],
          borderColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)'
          ],
          data: []
        }]
      },
      listingsCatPrevData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)'
          ],
          borderColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)'
          ],
          data: []
        }]
      },
      videosViewsData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      videosCatData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1)',
            'rgba(163, 95, 60, 1)'
          ],
          borderColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1',
            'rgba(163, 95, 60, 1)'
          ],
          data: []
        }]
      },
      videosCatPrevData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1)',
            'rgba(163, 95, 60, 1)'
          ],
          borderColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1)',
            'rgba(163, 95, 60, 1)'
          ],
          data: []
        }]
      },
      resourceViewsData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      resourceCatData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1)',
            'rgba(163, 95, 60, 1)'
          ],
          borderColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1',
            'rgba(163, 95, 60, 1)'
          ],
          data: []
        }]
      },
      resourceCatPrevData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1)',
            'rgba(163, 95, 60, 1)'
          ],
          borderColor: [
            'rgba(0, 200, 255, 1)',
            'rgba(255,160,0,1)',
            'rgba(56, 235, 140, 1)',
            'rgba(235, 105, 91, 1)',
            'rgba(240, 210, 65. 1)',
            'rgba(107, 28, 163, 1)',
            'rgba(163, 95, 60, 1)'
          ],
          data: []
        }]
      }
    }
  },
  mounted () {
    this.initialise(4)
  },
  computed: {
    dateRangeText () {
      let dateText
      if (this.dateFilter === 5) {
        dateText = this.dates.join(' ~ ')
      } else {
        this.dateOptions.forEach(element => {
          if (element.value !== 5 && element.value === this.dateFilter) {
            dateText = element.text
          }
        })
      }
      return dateText
    }
  },
  methods: {
    async initialise (filter) {
      this.$emit('status', 'loading')
      this.chartLoaded = false
      let filterObject = {}
      this.dates = []
      this.resetData()
      filterObject = {
        filterType: filter
      }
      await getBehaviour.getBehaviourMetrics(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.$emit('status', 'finished')
          } else {
            this.$emit('status', 'behaviourEngagementError')
          }
        })
      await getBehaviour.getBehaviourListings(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.$emit('status', 'finished')
          } else {
            this.noListings = true
            this.noListingsAlert = true
            this.$emit('status', 'behaviourListingError')
          }
        })
      await getBehaviour.getBehaviourVideos(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.$emit('status', 'finished')
          } else {
            this.noVideos = true
            this.noVideosAlert = true
            this.$emit('status', 'behaviourVideoError')
          }
        })
      await getBehaviour.getBehaviourResources(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.$emit('status', 'finished')
          } else {
            this.noResources = true
            this.noResourcesAlert = true
            this.$emit('status', 'behaviourResourceError')
          }
        })
    },
    async getPreSetBehaviour (value) {
      this.dateFilter = value
      this.dates = []
      this.resetData()
      let filterObject = {}
      if (this.dateFilter !== 5) {
        this.loadSnack()
        filterObject = {
          filterType: this.dateFilter
        }
        await getBehaviour.getBehaviourMetrics(filterObject)
          .then((response) => {
            this.assignResponse(response)
          })
        await getBehaviour.getBehaviourListings(filterObject)
          .then((response) => {
            this.assignResponse(response)
          })
        await getBehaviour.getBehaviourVideos(filterObject)
          .then((response) => {
            this.assignResponse(response)
          })
        await getBehaviour.getBehaviourResources(filterObject)
          .then((response) => {
            this.assignResponse(response)
          })
      }
    },
    async getCustomDateBehaviour () {
      this.loadSnack()
      this.$refs.menu.save(this.dates)
      this.menu = false
      this.resetData()
      let filterObject = {}
      if (this.dateFilter === 5) {
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          await getBehaviour.getBehaviourMetrics(filterObject)
            .then((response) => {
              this.assignResponse(response)
            })
          await getBehaviour.getBehaviourListings(filterObject)
            .then((response) => {
              this.assignResponse(response)
            })
          await getBehaviour.getBehaviourVideos(filterObject)
            .then((response) => {
              this.assignResponse(response)
            })
          await getBehaviour.getBehaviourResources(filterObject)
            .then((response) => {
              this.assignResponse(response)
            })
        } else {
          this.errorSnack()
        }
      }
    },
    assignResponse (response) {
      this.responseCode = response.status
      if (this.responseCode === 200) {
        if (response.config.url.includes('listings')) {
          this.noListingsAlert = false
          this.noListings = false
          this.nextListingCursor = response.data.nextCursor
          this.prevListingCursor = response.data.previousCursor
          this.$set(this, 'listings', response.data.results)
        } else if (response.config.url.includes('videos')) {
          this.noVideosAlert = false
          this.noVideos = false
          this.nextVideoCursor = response.data.nextCursor
          this.prevVideoCursor = response.data.previousCursor
          this.$set(this, 'videos', response.data.results)
        } else if (response.config.url.includes('resources')) {
          this.noResourcesAlert = false
          this.noResources = false
          this.nextResourceCursor = response.data.nextCursor
          this.prevResourceCursor = response.data.previousCursor
          this.$set(this, 'resources', response.data.results)
        } else {
          const data = response.data
          this.$set(this, 'articleViewCat', data.articleViewCategoryBreakdown)
          this.$set(this, 'articleViewCatPrev', data.articleViewCategoryBreakdownPrevious)
          this.engagement.engagements = data.engagementBreakdown.engagements
          this.engagement.engagements.forEach(element => {
            this.engagementSingleData.labels.push(element.date)
            this.engagementSingleData.datasets[0].data.push(element.views)
          })
          this.engagement.users = data.engagementBreakdown.users
          this.engagement.users.forEach(element => {
            this.engagementSingleData.datasets[1].data.push(element.users)
          })
          this.engagementPrev.engagements = data.engagementBreakdownPrevious.engagements
          this.engagementPrev.engagements.forEach(element => {
            this.engagementSingleData.datasets[2].data.push(element.views)
          })
          this.engagementPrev.users = data.engagementBreakdownPrevious.users
          this.engagementPrev.users.forEach(element => {
            this.engagementSingleData.datasets[3].data.push(element.users)
          })
          this.$set(this, 'listingView', data.listingViewBreakdownByDate)
          this.listingView.forEach(element => {
            this.listingsViewsData.labels.push(element.date)
            this.listingsViewsData.datasets[0].data.push(element.views)
          })
          this.$set(this, 'listingViewPrev', data.listingViewBreakdownByDatePrevious)
          this.listingViewPrev.forEach(element => {
            this.listingsViewsData.datasets[1].data.push(element.views)
          })
          this.$set(this, 'listingViewCat', data.listingViewCategoryBreakdown)
          this.listingViewCat.forEach(element => {
            this.listingsCatData.labels.push(element.category)
            let percentage = element.percentage
            percentage = parseFloat(percentage.replace('%', ''))
            this.listingsCatData.datasets[0].data.push(percentage)
          })
          this.$set(this, 'listingViewCatPrev', data.listingViewCategoryBreakdownPrevious)
          this.listingViewCatPrev.forEach(element => {
            this.listingsCatPrevData.labels.push(element.category)
            let percentage = element.percentage
            percentage = parseFloat(percentage.replace('%', ''))
            this.listingsCatPrevData.datasets[0].data.push(percentage)
          })
          this.$set(this, 'videoView', data.videoViewBreakdownByDate)
          this.videoView.forEach(element => {
            this.videosViewsData.labels.push(element.date)
            this.videosViewsData.datasets[0].data.push(element.views)
          })
          this.$set(this, 'videoViewPrev', data.videoViewBreakdownByDatePrevious)
          this.videoViewPrev.forEach(element => {
            this.videosViewsData.datasets[1].data.push(element.views)
          })
          this.$set(this, 'videoViewCat', data.videoViewCategoryBreakdown)
          this.videoViewCat.forEach(element => {
            this.videosCatData.labels.push(element.category)
            let percentage = element.percentage
            percentage = parseFloat(percentage.replace('%', ''))
            this.videosCatData.datasets[0].data.push(percentage)
          })
          this.$set(this, 'videoViewCatPrev', data.videoViewCategoryBreakdownPrevious)
          this.videoViewCatPrev.forEach(element => {
            this.videosCatPrevData.labels.push(element.category)
            let percentage = element.percentage
            percentage = parseFloat(percentage.replace('%', ''))
            this.videosCatPrevData.datasets[0].data.push(percentage)
          })
          this.$set(this, 'resourceView', data.resourceViewBreakdownByDate)
          this.resourceView.forEach(element => {
            this.resourceViewsData.labels.push(element.date)
            this.resourceViewsData.datasets[0].data.push(element.views)
          })
          this.$set(this, 'resourceViewPrev', data.resourceViewBreakdownByDatePrevious)
          this.resourceViewPrev.forEach(element => {
            this.resourceViewsData.datasets[1].data.push(element.views)
          })
          this.$set(this, 'resourceViewCat', data.resourceViewCategoryBreakdown)
          this.resourceViewCat.forEach(element => {
            this.resourceCatData.labels.push(element.category)
            let percentage = element.percentage
            percentage = parseFloat(percentage.replace('%', ''))
            this.resourceCatData.datasets[0].data.push(percentage)
          })
          this.$set(this, 'resourceViewCatPrev', data.resourceViewCategoryBreakdownPrevious)
          this.resourceViewCatPrev.forEach(element => {
            this.resourceCatPrevData.labels.push(element.category)
            let percentage = element.percentage
            percentage = parseFloat(percentage.replace('%', ''))
            this.resourceCatPrevData.datasets[0].data.push(percentage)
          })
          this.$set(this, 'frequency', data.frequency)
          this.frequency.forEach(element => {
            if (element.sessionCount === 1) {
              let total = this.frequencyData.datasets[0].data[0]
              total = total + element.users
              this.frequencyData.datasets[0].data[0] = total
            } else if (element.sessionCount === 2) {
              let total = this.frequencyData.datasets[0].data[1]
              total = total + element.users
              this.frequencyData.datasets[0].data[1] = total
            } else if (element.sessionCount === 3) {
              let total = this.frequencyData.datasets[0].data[2]
              total = total + element.users
              this.frequencyData.datasets[0].data[2] = total
            } else if (element.sessionCount === 4) {
              let total = this.frequencyData.datasets[0].data[3]
              total = total + element.users
              this.frequencyData.datasets[0].data[3] = total
            } else if (element.sessionCount === 5) {
              let total = this.frequencyData.datasets[0].data[4]
              total = total + element.users
              this.frequencyData.datasets[0].data[4] = total
            } else if (element.sessionCount === 6) {
              let total = this.frequencyData.datasets[0].data[5]
              total = total + element.users
              this.frequencyData.datasets[0].data[5] = total
            } else if (element.sessionCount === 7) {
              let total = this.frequencyData.datasets[0].data[6]
              total = total + element.users
              this.frequencyData.datasets[0].data[6] = total
            } else if (element.sessionCount === 8) {
              let total = this.frequencyData.datasets[0].data[7]
              total = total + element.users
              this.frequencyData.datasets[0].data[7] = total
            } else if (element.sessionCount >= 9 && element.sessionCount <= 14) {
              let total = this.frequencyData.datasets[0].data[8]
              total = total + element.users
              this.frequencyData.datasets[0].data[8] = total
            } else if (element.sessionCount >= 15 && element.sessionCount <= 25) {
              let total = this.frequencyData.datasets[0].data[9]
              total = total + element.users
              this.frequencyData.datasets[0].data[9] = total
            } else if (element.sessionCount >= 26 && element.sessionCount <= 50) {
              let total = this.frequencyData.datasets[0].data[10]
              total = total + element.users
              this.frequencyData.datasets[0].data[10] = total
            } else if (element.sessionCount >= 51 && element.sessionCount <= 100) {
              let total = this.frequencyData.datasets[0].data[11]
              total = total + element.users
              this.frequencyData.datasets[0].data[11] = total
            } else if (element.sessionCount >= 201) {
              let total = this.frequencyData.datasets[0].data[12]
              total = total + element.users
              this.frequencyData.datasets[0].data[12] = total
            }
          })
          this.$set(this, 'frequencyPrev', data.frequencyPrevious)
          this.frequencyPrev.forEach(element => {
            if (element.sessionCount === 1) {
              let total = this.frequencyData.datasets[1].data[0]
              total = total + element.users
              this.frequencyData.datasets[1].data[0] = total
            } else if (element.sessionCount === 2) {
              let total = this.frequencyData.datasets[1].data[1]
              total = total + element.users
              this.frequencyData.datasets[1].data[1] = total
            } else if (element.sessionCount === 3) {
              let total = this.frequencyData.datasets[1].data[2]
              total = total + element.users
              this.frequencyData.datasets[1].data[2] = total
            } else if (element.sessionCount === 4) {
              let total = this.frequencyData.datasets[1].data[3]
              total = total + element.users
              this.frequencyData.datasets[1].data[3] = total
            } else if (element.sessionCount === 5) {
              let total = this.frequencyData.datasets[1].data[4]
              total = total + element.users
              this.frequencyData.datasets[1].data[4] = total
            } else if (element.sessionCount === 6) {
              let total = this.frequencyData.datasets[1].data[5]
              total = total + element.users
              this.frequencyData.datasets[1].data[5] = total
            } else if (element.sessionCount === 7) {
              let total = this.frequencyData.datasets[1].data[6]
              total = total + element.users
              this.frequencyData.datasets[1].data[6] = total
            } else if (element.sessionCount === 8) {
              let total = this.frequencyData.datasets[1].data[7]
              total = total + element.users
              this.frequencyData.datasets[1].data[7] = total
            } else if (element.sessionCount >= 9 && element.sessionCount <= 14) {
              let total = this.frequencyData.datasets[1].data[8]
              total = total + element.users
              this.frequencyData.datasets[1].data[8] = total
            } else if (element.sessionCount >= 15 && element.sessionCount <= 25) {
              let total = this.frequencyData.datasets[1].data[9]
              total = total + element.users
              this.frequencyData.datasets[1].data[9] = total
            } else if (element.sessionCount >= 26 && element.sessionCount <= 50) {
              let total = this.frequencyData.datasets[1].data[10]
              total = total + element.users
              this.frequencyData.datasets[1].data[10] = total
            } else if (element.sessionCount >= 51 && element.sessionCount <= 100) {
              let total = this.frequencyData.datasets[1].data[11]
              total = total + element.users
              this.frequencyData.datasets[1].data[11] = total
            } else if (element.sessionCount >= 201) {
              let total = this.frequencyData.datasets[1].data[12]
              total = total + element.users
              this.frequencyData.datasets[1].data[12] = total
            }
          })
          this.$set(this, 'recency', data.recency)
          this.recency.forEach(element => {
            if (element.day === 0) {
              let total = this.recencyData.datasets[0].data[0]
              total = total + element.sessions
              this.recencyData.datasets[0].data[0] = total
            } else if (element.day === 1) {
              let total = this.recencyData.datasets[0].data[1]
              total = total + element.sessions
              this.recencyData.datasets[0].data[1] = total
            } else if (element.day === 2) {
              let total = this.recencyData.datasets[0].data[2]
              total = total + element.sessions
              this.recencyData.datasets[0].data[2] = total
            } else if (element.day === 3) {
              let total = this.recencyData.datasets[0].data[3]
              total = total + element.sessions
              this.recencyData.datasets[0].data[3] = total
            } else if (element.day === 4) {
              let total = this.recencyData.datasets[0].data[4]
              total = total + element.sessions
              this.recencyData.datasets[0].data[4] = total
            } else if (element.day === 5) {
              let total = this.recencyData.datasets[0].data[5]
              total = total + element.sessions
              this.recencyData.datasets[0].data[5] = total
            } else if (element.day === 6) {
              let total = this.recencyData.datasets[0].data[6]
              total = total + element.sessions
              this.recencyData.datasets[0].data[6] = total
            } else if (element.day === 7) {
              let total = this.recencyData.datasets[0].data[7]
              total = total + element.sessions
              this.recencyData.datasets[0].data[7] = total
            } else if (element.day >= 8 && element.day <= 14) {
              let total = this.recencyData.datasets[0].data[8]
              total = total + element.sessions
              this.recencyData.datasets[0].data[8] = total
            } else if (element.day >= 15 && element.day <= 30) {
              let total = this.recencyData.datasets[0].data[9]
              total = total + element.sessions
              this.recencyData.datasets[0].data[9] = total
            } else if (element.day >= 31 && element.day <= 60) {
              let total = this.recencyData.datasets[0].data[10]
              total = total + element.sessions
              this.recencyData.datasets[0].data[10] = total
            } else if (element.day >= 61 && element.day <= 120) {
              let total = this.recencyData.datasets[0].data[11]
              total = total + element.sessions
              this.recencyData.datasets[0].data[11] = total
            }
          })
          this.$set(this, 'recencyPrev', data.recencyPrevious)
          this.recencyPrev.forEach(element => {
            if (element.day === 0) {
              let total = this.recencyData.datasets[1].data[0]
              total = total + element.sessions
              this.recencyData.datasets[1].data[0] = total
            } else if (element.day === 1) {
              let total = this.recencyData.datasets[1].data[1]
              total = total + element.sessions
              this.recencyData.datasets[1].data[1] = total
            } else if (element.day === 2) {
              let total = this.recencyData.datasets[1].data[2]
              total = total + element.sessions
              this.recencyData.datasets[1].data[2] = total
            } else if (element.day === 3) {
              let total = this.recencyData.datasets[1].data[3]
              total = total + element.sessions
              this.recencyData.datasets[1].data[3] = total
            } else if (element.day === 4) {
              let total = this.recencyData.datasets[1].data[4]
              total = total + element.sessions
              this.recencyData.datasets[1].data[4] = total
            } else if (element.day === 5) {
              let total = this.recencyData.datasets[1].data[5]
              total = total + element.sessions
              this.recencyData.datasets[1].data[5] = total
            } else if (element.day === 6) {
              let total = this.recencyData.datasets[1].data[6]
              total = total + element.sessions
              this.recencyData.datasets[1].data[6] = total
            } else if (element.day === 7) {
              let total = this.recencyData.datasets[1].data[7]
              total = total + element.sessions
              this.recencyData.datasets[1].data[7] = total
            } else if (element.day >= 8 && element.day <= 14) {
              let total = this.recencyData.datasets[1].data[8]
              total = total + element.sessions
              this.recencyData.datasets[1].data[8] = total
            } else if (element.day >= 15 && element.day <= 30) {
              let total = this.recencyData.datasets[1].data[9]
              total = total + element.sessions
              this.recencyData.datasets[1].data[9] = total
            } else if (element.day >= 31 && element.day <= 60) {
              let total = this.recencyData.datasets[1].data[10]
              total = total + element.sessions
              this.recencyData.datasets[1].data[10] = total
            } else if (element.day >= 61 && element.day <= 120) {
              let total = this.recencyData.datasets[1].data[11]
              total = total + element.sessions
              this.recencyData.datasets[1].data[11] = total
            }
          })
        }
        this.chartLoaded = true
        this.listingLoading = false
        this.videoLoading = false
        this.resourceLoading = false
        this.finishSnack()
      } else if (this.responseCode === 404) {
        if (response.data.message.includes('Listings')) {
          this.noListingsAlert = true
          this.noListings = true
        } else if (response.data.message.includes('Videos')) {
          this.noVideosAlert = true
          this.noVideos = true
        } else if (response.data.message.includes('Resources')) {
          this.noResourcesAlert = true
          this.noResources = true
        }
        this.errorSnack()
      }
    },
    resetData () {
      this.listingsViewsData.labels = []
      this.listingsViewsData.datasets.forEach(element => {
        element.data = []
      })
      this.listingsCatData.labels = []
      this.listingsCatData.datasets[0].data = []
      this.listingsCatPrevData.labels = []
      this.listingsCatPrevData.datasets[0].data = []
      this.videosViewsData.labels = []
      this.videosViewsData.datasets.forEach(element => {
        element.data = []
      })
      this.videosCatData.labels = []
      this.videosCatData.datasets[0].data = []
      this.videosCatPrevData.labels = []
      this.videosCatPrevData.datasets[0].data = []
      this.resourceViewsData.labels = []
      this.resourceViewsData.datasets.forEach(element => {
        element.data = []
      })
      this.resourceCatData.labels = []
      this.resourceCatData.datasets[0].data = []
      this.resourceCatPrevData.labels = []
      this.resourceCatPrevData.datasets[0].data = []
      this.engagementSingleData.labels = []
      this.engagementSingleData.datasets.forEach(element => {
        element.data = []
      })
      this.frequencyData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.frequencyData.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.recencyData.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.recencyData.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      this.videos = []
      this.listings = []
      this.resources = []
    },
    loadSnack () {
      this.chartLoaded = false
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
    },
    finishSnack () {
      this.snackColor = 'success'
      this.snackText = 'Done'
      this.snackTimeout = 3000
      this.chartLoaded = true
    },
    errorSnack () {
      this.snackColor = 'error'
      this.snackText = 'Error'
      this.snackTimeout = 3000
    },
    async toggleListingOrder () {
      let filterObject = {}
      this.listingSortOrder = !this.listingSortOrder
      if (this.dateFilter !== 5) {
        this.listingLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (this.listingSortOrder === true) {
          const sortby = 'asc'
          await getBehaviour.getBehaviourListings(filterObject, sortby)
            .then((response) => {
              this.assignResponse(response)
            })
        } else if (this.listingSortOrder === false) {
          const sortby = 'desc'
          await getBehaviour.getBehaviourListings(filterObject, sortby)
            .then((response) => {
              this.assignResponse(response)
            })
        }
      } else if (this.dateFilter === 5) {
        this.loading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (this.listingSortOrder === true) {
            const sortby = 'asc'
            await getBehaviour.getBehaviourListings(filterObject, sortby)
              .then((response) => {
                this.assignResponse(response)
              })
          } else if (this.listingSortOrder === false) {
            const sortby = 'desc'
            await getBehaviour.getBehaviourListings(filterObject, sortby)
              .then((response) => {
                this.assignResponse(response)
              })
          }
        }
      }
    },
    async toggleVideoOrder () {
      let filterObject = {}
      this.videoSortOrder = !this.videoSortOrder
      if (this.dateFilter !== 5) {
        this.videoLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (this.videoSortOrder === true) {
          const sortby = 'asc'
          await getBehaviour.getBehaviourVideos(filterObject, sortby)
            .then((response) => {
              this.assignResponse(response)
            })
        } else if (this.videoSortOrder === false) {
          const sortby = 'desc'
          await getBehaviour.getBehaviourVideos(filterObject, sortby)
            .then((response) => {
              this.assignResponse(response)
            })
        }
      } else if (this.dateFilter === 5) {
        this.videoLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (this.videoSortOrder === true) {
            const sortby = 'asc'
            await getBehaviour.getBehaviourVideos(filterObject, sortby)
              .then((response) => {
                this.assignResponse(response)
              })
          } else if (this.videoSortOrder === false) {
            const sortby = 'desc'
            await getBehaviour.getBehaviourVideos(filterObject, sortby)
              .then((response) => {
                this.assignResponse(response)
              })
          }
        }
      }
    },
    async toggleResourceOrder () {
      let filterObject = {}
      this.resourceSortOrder = !this.resourceSortOrder
      if (this.dateFilter !== 5) {
        this.resourceLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (this.resourceSortOrder === true) {
          const sortby = 'asc'
          await getBehaviour.getBehaviourResources(filterObject, sortby)
            .then((response) => {
              this.assignResponse(response)
            })
        } else if (this.resourceSortOrder === false) {
          const sortby = 'desc'
          await getBehaviour.getBehaviourResources(filterObject, sortby)
            .then((response) => {
              this.assignResponse(response)
            })
        }
      } else if (this.dateFilter === 5) {
        this.loading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (this.resourceSortOrder === true) {
            const sortby = 'asc'
            await getBehaviour.getBehaviourResources(filterObject, sortby)
              .then((response) => {
                this.assignResponse(response)
              })
          } else if (this.resourceSortOrder === false) {
            const sortby = 'desc'
            await getBehaviour.getBehaviourResources(filterObject, sortby)
              .then((response) => {
                this.assignResponse(response)
              })
          }
        }
      }
    },
    async previousListingPage () {
      let filterObject = {}
      let sortby
      if (this.listingSortOrder === true) {
        sortby = 'asc'
      } else if (this.listingSortOrder === false) {
        sortby = 'desc'
      }
      const cursorType = 'previous'
      const cursor = this.prevListingCursor
      const rowsPerPage = this.rowsPerPage
      if (this.dateFilter !== 5) {
        this.listingLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (sortby !== null) {
          await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
            .then((response) => {
              this.$set(this, 'listings', response.data.results)
              this.$set(this, 'prevListingCursor', response.data.previousCursor)
              this.$set(this, 'nextListingCursor', response.data.nextCursor)
              this.listingLoading = false
            })
        } else {
          await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage)
            .then((response) => {
              this.$set(this, 'listings', response.data.results)
              this.$set(this, 'prevListingCursor', response.data.previousCursor)
              this.$set(this, 'nextListingCursor', response.data.nextCursor)
              this.listingLoading = false
            })
        }
      } else if (this.dateFilter === 5) {
        this.listingLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (sortby !== null) {
            await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
              .then((response) => {
                this.$set(this, 'listings', response.data.results)
                this.$set(this, 'prevListingCursor', response.data.previousCursor)
                this.$set(this, 'nextListingCursor', response.data.nextCursor)
                this.listingLoading = false
              })
          } else {
            await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage)
              .then((response) => {
                this.$set(this, 'listings', response.data.results)
                this.$set(this, 'prevListingCursor', response.data.previousCursor)
                this.$set(this, 'nextListingCursor', response.data.nextCursor)
                this.listingLoading = false
              })
          }
        }
      }
    },
    async nextListingPage () {
      let filterObject = {}
      let sortby
      if (this.listingSortOrder === true) {
        sortby = 'asc'
      } else if (this.listingSortOrder === false) {
        sortby = 'desc'
      }
      const cursorType = 'next'
      const cursor = this.nextListingCursor
      const rowsPerPage = this.rowsPerPage
      if (this.dateFilter !== 5) {
        this.listingLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (sortby !== null) {
          await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
            .then((response) => {
              this.$set(this, 'listings', response.data.results)
              this.$set(this, 'prevListingCursor', response.data.previousCursor)
              this.$set(this, 'nextListingCursor', response.data.nextCursor)
              this.listingLoading = false
            })
        } else {
          await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage)
            .then((response) => {
              this.$set(this, 'listings', response.data.results)
              this.$set(this, 'prevListingCursor', response.data.previousCursor)
              this.$set(this, 'nextListingCursor', response.data.nextCursor)
              this.listingLoading = false
            })
        }
      } else if (this.dateFilter === 5) {
        this.listingLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (sortby !== null) {
            await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
              .then((response) => {
                this.$set(this, 'listings', response.data.results)
                this.$set(this, 'prevListingCursor', response.data.previousCursor)
                this.$set(this, 'nextListingCursor', response.data.nextCursor)
                this.listingLoading = false
              })
          } else {
            await getBehaviour.getBehaviourListingsPage(filterObject, cursorType, cursor, rowsPerPage)
              .then((response) => {
                this.$set(this, 'listings', response.data.results)
                this.$set(this, 'prevListingCursor', response.data.previousCursor)
                this.$set(this, 'nextListingCursor', response.data.nextCursor)
                this.listingLoading = false
              })
          }
        }
      }
    },
    async previousVideoPage () {
      let filterObject = {}
      let sortby
      if (this.videoSortOrder === true) {
        sortby = 'asc'
      } else if (this.videoSortOrder === false) {
        sortby = 'desc'
      }
      const cursorType = 'previous'
      const cursor = this.prevVideoCursor
      const rowsPerPage = this.rowsPerPage
      if (this.dateFilter !== 5) {
        this.videoLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (sortby !== null) {
          await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
            .then((response) => {
              this.$set(this, 'videos', response.data.results)
              this.$set(this, 'prevVideoCursor', response.data.previousCursor)
              this.$set(this, 'nextVideoCursor', response.data.nextCursor)
              this.videoLoading = false
            })
        } else {
          await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage)
            .then((response) => {
              this.$set(this, 'videos', response.data.results)
              this.$set(this, 'prevVideoCursor', response.data.previousCursor)
              this.$set(this, 'nextVideoCursor', response.data.nextCursor)
              this.videoLoading = false
            })
        }
      } else if (this.dateFilter === 5) {
        this.videoLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (sortby !== null) {
            await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
              .then((response) => {
                this.$set(this, 'videos', response.data.results)
                this.$set(this, 'prevVideoCursor', response.data.previousCursor)
                this.$set(this, 'nextVideoCursor', response.data.nextCursor)
                this.videoLoading = false
              })
          } else {
            await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage)
              .then((response) => {
                this.$set(this, 'videos', response.data.results)
                this.$set(this, 'prevVideoCursor', response.data.previousCursor)
                this.$set(this, 'nextVideoCursor', response.data.nextCursor)
                this.videoLoading = false
              })
          }
        }
      }
    },
    async nextVideoPage () {
      let filterObject = {}
      let sortby
      if (this.videoSortOrder === true) {
        sortby = 'asc'
      } else if (this.videoSortOrder === false) {
        sortby = 'desc'
      }
      const cursorType = 'next'
      const cursor = this.nextVideoCursor
      const rowsPerPage = this.rowsPerPage
      if (this.dateFilter !== 5) {
        this.videoLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (sortby !== null) {
          await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
            .then((response) => {
              this.$set(this, 'videos', response.data.results)
              this.$set(this, 'prevVideoCursor', response.data.previousCursor)
              this.$set(this, 'nextVideoCursor', response.data.nextCursor)
              this.videoLoading = false
            })
        } else {
          await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage)
            .then((response) => {
              this.$set(this, 'videos', response.data.results)
              this.$set(this, 'prevVideoCursor', response.data.previousCursor)
              this.$set(this, 'nextVideoCursor', response.data.nextCursor)
              this.videoLoading = false
            })
        }
      } else if (this.dateFilter === 5) {
        this.videoLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (sortby !== null) {
            await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
              .then((response) => {
                this.$set(this, 'videos', response.data.results)
                this.$set(this, 'prevVideoCursor', response.data.previousCursor)
                this.$set(this, 'nextVideoCursor', response.data.nextCursor)
                this.videoLoading = false
              })
          } else {
            await getBehaviour.getBehaviourVideosPage(filterObject, cursorType, cursor, rowsPerPage)
              .then((response) => {
                this.$set(this, 'videos', response.data.results)
                this.$set(this, 'prevVideoCursor', response.data.previousCursor)
                this.$set(this, 'nextVideoCursor', response.data.nextCursor)
                this.videoLoading = false
              })
          }
        }
      }
    },
    async previousResourcePage () {
      let filterObject = {}
      let sortby
      if (this.resourceSortOrder === true) {
        sortby = 'asc'
      } else if (this.resourceSortOrder === false) {
        sortby = 'desc'
      }
      const cursorType = 'previous'
      const cursor = this.prevResourceCursor
      const rowsPerPage = this.rowsPerPage
      if (this.dateFilter !== 5) {
        this.resourceLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (sortby !== null) {
          await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
            .then((response) => {
              this.$set(this, 'resources', response.data.results)
              this.$set(this, 'prevResourceCursor', response.data.previousCursor)
              this.$set(this, 'nextResourceCursor', response.data.nextCursor)
              this.resourceLoading = false
            })
        } else {
          await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage)
            .then((response) => {
              this.$set(this, 'resources', response.data.results)
              this.$set(this, 'prevResourceCursor', response.data.previousCursor)
              this.$set(this, 'nextresourceCursor', response.data.nextCursor)
              this.resourceLoading = false
            })
        }
      } else if (this.dateFilter === 5) {
        this.resourceLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (sortby !== null) {
            await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
              .then((response) => {
                this.$set(this, 'resources', response.data.results)
                this.$set(this, 'prevResourceCursor', response.data.previousCursor)
                this.$set(this, 'nextResourceCursor', response.data.nextCursor)
                this.resourceLoading = false
              })
          } else {
            await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage)
              .then((response) => {
                this.$set(this, 'resources', response.data.results)
                this.$set(this, 'prevResourceCursor', response.data.previousCursor)
                this.$set(this, 'nextResourceCursor', response.data.nextCursor)
                this.listingLoading = false
              })
          }
        }
      }
    },
    async nextResourcePage () {
      let filterObject = {}
      let sortby
      if (this.resourceSortOrder === true) {
        sortby = 'asc'
      } else if (this.resourceSortOrder === false) {
        sortby = 'desc'
      }
      const cursorType = 'next'
      const cursor = this.nextResourceCursor
      const rowsPerPage = this.rowsPerPage
      if (this.dateFilter !== 5) {
        this.resourceLoading = true
        filterObject = {
          filterType: this.dateFilter
        }
        if (sortby !== null) {
          await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
            .then((response) => {
              this.$set(this, 'resources', response.data.results)
              this.$set(this, 'prevResourceCursor', response.data.previousCursor)
              this.$set(this, 'nextResourceCursor', response.data.nextCursor)
              this.resourceLoading = false
            })
        } else {
          await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage)
            .then((response) => {
              this.$set(this, 'resources', response.data.results)
              this.$set(this, 'prevResourceCursor', response.data.previousCursor)
              this.$set(this, 'nextResourceCursor', response.data.nextCursor)
              this.resourceLoading = false
            })
        }
      } else if (this.dateFilter === 5) {
        this.resourceLoading = true
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.dateFilter,
            startDate: startDate,
            endDate: endDate
          }
          if (sortby !== null) {
            await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage, sortby)
              .then((response) => {
                this.$set(this, 'resources', response.data.results)
                this.$set(this, 'prevResourceCursor', response.data.previousCursor)
                this.$set(this, 'nextResourceCursor', response.data.nextCursor)
                this.resourceLoading = false
              })
          } else {
            await getBehaviour.getBehaviourResourcesPage(filterObject, cursorType, cursor, rowsPerPage)
              .then((response) => {
                this.$set(this, 'resources', response.data.results)
                this.$set(this, 'prevResourceCursor', response.data.previousCursor)
                this.$set(this, 'nextResourceCursor', response.data.nextCursor)
                this.resourceLoading = false
              })
          }
        }
      }
    }
  }
}
</script>
