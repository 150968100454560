<template>
  <div>
    <v-card>
      <v-card-title class="text-center justify-center py-6">
        <h1 class="font-weight-bold display-3">Your Insights</h1>
      </v-card-title>
      <v-card
        color="grey lighten-4"
        tile
      >
        <v-toolbar
          color="primary"
          elevation="4"
          dark
        >
          <v-tabs
            v-model="tab"
            centered
            icons-and-text
            grow
          >
            <!-- <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ item }}
            </v-tab> -->
            <v-tab>
              Highlights
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-tab>

            <v-tab>
              Users
              <v-icon>mdi-account-multiple</v-icon>
            </v-tab>

            <v-tab>
              Behaviour
              <v-icon>mdi-arrow-decision</v-icon>
            </v-tab>
            <v-tab>
              Search
              <v-icon>mdi-magnify</v-icon>
            </v-tab>

            <v-tab>
              Location
              <v-icon>mdi-map-search</v-icon>
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-card>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-alert
            v-if="highlightsAlert"
            border="right"
            colored-border
            type="error"
            elevation="2"
          >
            <v-row align="center">
              <v-col class="grow text-h3">Failed to load Highlights. Please try again later</v-col>
              <v-col class="shrink">
                <v-btn @click="highlightsAlert = false">OK</v-btn>
              </v-col>
            </v-row>
          </v-alert>
          <Highlights v-if="highlightsAlert === false" @status="displayLoader" />
        </v-tab-item>
        <v-tab-item>
          <v-alert
            v-if="userAlert"
            border="right"
            colored-border
            type="error"
            elevation="2"
          >
            <v-row align="center">
              <v-col class="grow text-h3">Failed to load User Metrics</v-col>
            </v-row>
          </v-alert>
          <Users @status="displayLoader" />
        </v-tab-item>
        <v-tab-item>
          <v-alert
            v-if="behaviourAlert.display"
            border="right"
            colored-border
            type="error"
            elevation="2"
          >
            <v-row align="center">
              <div v-for="(error, index) in behaviourAlert.errors" :key="index">
                <v-col class="grow text-h3">{{error}}</v-col>
              </div>
            </v-row>
          </v-alert>
          <Behaviour @status="displayLoader" />
        </v-tab-item>
        <v-tab-item>
          <v-alert
            v-if="searchAlert"
            border="right"
            colored-border
            type="error"
            elevation="2"
          >
            <v-row align="center">
              <v-col class="grow text-h3">Failed to load Search Metrics</v-col>
            </v-row>
          </v-alert>
          <Search @status="displayLoader" />
        </v-tab-item>
        <v-tab-item>
          <Location @status="displayLoader" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
      <v-progress-circular
        v-if="snackColor === 'info'"
        color="white"
        indeterminate
      ></v-progress-circular>
      {{ snackText }}
    </v-snackbar>
  </div>
</template>

<script>
import Highlights from '@/views/dashboard/analyticsTabs/Highlights'
import Users from '@/views/dashboard/analyticsTabs/Users'
import Behaviour from '@/views/dashboard/analyticsTabs/Behaviour'
import Search from '@/views/dashboard/analyticsTabs/Search'
import Location from '@/views/dashboard/analyticsTabs/Location'
export default {
  name: 'FrogDashboard',
  components: {
    Highlights,
    Users,
    Behaviour,
    Search,
    Location
  },
  data () {
    return {
      userAlert: false,
      searchAlert: false,
      highlightsAlert: false,
      behaviourAlert: {
        display: false,
        errors: ['Could not load the following for the selected date range: ']
      },
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      tab: null,
      items: [
        'Highlights',
        'Users',
        'Behaviour',
        'Search',
        'Location'
      ]
    }
  },
  methods: {
    displayLoader (value) {
      switch (value) {
        case 'loading':
          this.displaySnack('info', 'Loading...', 20000)
          break
        case 'finished':
          this.displaySnack('success', 'Done', 3000)
          break
        case 'highlightsError':
          this.displaySnack('error', 'Failed to load highlights', 5000)
          this.highlightsAlert = true
          break
        case 'behaviourEngagementError':
          this.displaySnack('error', 'Failed to load engagements', 5000)
          this.behaviourAlert.display = true
          this.behaviourAlert.errors.push('engagements')
          break
        case 'behaviourListingError':
          this.displaySnack('error', 'Failed to load listings', 5000)
          this.behaviourAlert.display = true
          this.behaviourAlert.errors.push('Listings')
          break
        case 'behaviourVideoError':
          this.displaySnack('error', 'Failed to load videos', 5000)
          this.behaviourAlert.display = true
          this.behaviourAlert.errors.push('Videos')
          break
        case 'behaviourResourceError':
          this.displaySnack('error', 'Failed to load resources', 5000)
          this.behaviourAlert.display = true
          this.behaviourAlert.errors.push('Resources')
          break
        case 'searchError':
          this.displaySnack('error', 'Failed to load Search Metrics', 5000)
          this.searchAlert = true
          break
        case 'UserError':
          this.displaySnack('error', 'Failed to load User Metrics', 5000)
          this.userAlert = true
          break
      }
    },
    displaySnack (snackColor, snackText, snackTimeout) {
      this.snack = true
      this.snackColor = snackColor
      this.snackText = snackText
      this.snackTimeout = snackTimeout
    }
  }
}
</script>
