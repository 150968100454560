<template>
<div>
  <v-row>
    <v-col cols="4">
      <v-autocomplete v-model="selectedTags" :items="tagOptions" :loading="isLoading" :search-input.sync="searchTags" deletable clearable item-text="tagName" item-value="id" multiple return-object cache-items class="mt-4 pa-2" hide-selected hide-details hide-no-data label="Terms" placeholder="Select Terms to search, then add a date range" style="max-width: 80%; z-index: 999;" append-icon="mdi-magnify" v-on:input="searchTags = null" v-on:change="getCustomResults">
      </v-autocomplete>
    </v-col>
    <v-col cols="4">
      <!-- <v-text-field clear-icon :clearable="true" v-model="searchPost" label="Postcode Search" placeholder="Search for term stats within a postcode area" append-icon="mdi-map-marker" v-on:change="getCustomResults" style="max-width: 80%; z-index: 999" class="mt-4 pa-2"></v-text-field> -->
      <LocationSearch @locationEmitted="getLocation" @locationRemoved="removeLocation" style="max-width: 80%; z-index: 999" class="mt-4 pa-2"/>
    </v-col>
    <v-col cols="4">
      <v-menu z-index="999">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }" color="primary" medium class="mt-4">
                Date Range
                <v-icon right color="white">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <span>Select a date range</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in filterOptions" :key="index" @click="getPreSetHighlights(item.value)">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu z-index="999" v-if="selectedFilter === 5" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="mt-4">
            Select a Range
            <v-icon right>mdi-calendar-range</v-icon>
          </v-btn>
        </template>
        <v-date-picker v-model="dates" scrollable range z-index="5">
          <v-btn text color="dark" @click="menu = false">Cancel</v-btn>
          <v-btn :disabled="dates.length <= 1" text color="dark" @click="getCustomDateHighlights">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
  <v-tabs v-model="tab" background-color="white" grow>
    <v-tab href="#tab-1">
      Overall Insights
      <v-icon class="ml-2">mdi-chart-line-variant</v-icon>
    </v-tab>
    <v-tab href="#tab-2" v-if="!searchPost">
      Listing Insights
      <v-icon class="ml-2">mdi-card-bulleted</v-icon>
    </v-tab>
    <v-tab href="#tab-3" v-if="!searchPost">
      Video Insights
      <v-icon class="ml-2">mdi-video</v-icon>
    </v-tab>
    <v-tab href="#tab-4" v-if="!searchPost">
      Resource Insights
      <v-icon class="ml-2">mdi-folder</v-icon>
    </v-tab>
    <v-tab href="#tab-5">
      Location Insights
      <v-icon class="ml-2">mdi-map-search</v-icon>
    </v-tab>
  </v-tabs>
  <v-tabs-items v-if="chartloaded" v-model="tab">
    <v-tab-item id="tab-1">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-line" color="info" :title="'Trend of terms over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="termDateBreakdownData.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="termDateBreakdownData" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="termDateBreakdownData" />
                </div>
                <div v-if="termDateBreakdownData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="termDateBreakdownDataPrevious.labels.length !== 0">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="termDateBreakdownDataPrevious" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="termDateBreakdownDataPrevious" />
                </div>
                <div v-if="termDateBreakdownDataPrevious.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-bar" color="info" :title="'Top terms searched over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="topTermsData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topTermsData" />
                </div>
                <div v-if="topTermsData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="topTermsCompareData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topTermsCompareData" />
                </div>
                <div v-if="topTermsCompareData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-tab-item>
    <v-tab-item id="tab-2" v-if="!searchPost">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-line" color="info" :title="'Trend of listing terms over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="listingTermDateBreakdownData.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="listingTermDateBreakdownData" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="listingTermDateBreakdownData" />
                </div>
                <div v-if="listingTermDateBreakdownData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="listingTermDateBreakdownDataPrevious.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="listingTermDateBreakdownDataPrevious" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="listingTermDateBreakdownDataPrevious" />
                </div>
                <div v-if="listingTermDateBreakdownDataPrevious.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-bar" color="info" :title="'Top listing terms searched over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="topListingSearchesData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topListingSearchesData" />
                </div>
                <div v-if="topListingSearchesData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="topListingSearchesCompareData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topListingSearchesCompareData" />
                </div>
                <div v-if="topListingSearchesCompareData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-tab-item>
    <v-tab-item id="tab-3" v-if="!searchPost">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-line" color="info" :title="'Trend of video terms over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="videoTermDateBreakdownData.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="videoTermDateBreakdownData" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="videoTermDateBreakdownData" />
                </div>
                <div v-if="videoTermDateBreakdownData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="videoTermDateBreakdownDataPrevious.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="videoTermDateBreakdownDataPrevious" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="videoTermDateBreakdownDataPrevious" />
                </div>
                <div v-if="videoTermDateBreakdownDataPrevious.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-bar" color="info" :title="'Top video terms searched over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="topVideosSearchesData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topVideosSearchesData" />
                </div>
                <div v-if="topVideosSearchesData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="topVideosSearchesCompareData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topVideosSearchesCompareData" />
                </div>
                <div v-if="topVideosSearchesCompareData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-tab-item>
    <v-tab-item id="tab-4" v-if="!searchPost">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-line" color="info" :title="'Trend of resource terms over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="resourceTermDateBreakdownData.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="resourceTermDateBreakdownData" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="resourceTermDateBreakdownData" />
                </div>
                <div v-if="resourceTermDateBreakdownData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="resourceTermDateBreakdownDataPrevious.labels.length >= 1">
                  <line-chart style="width: 100%;" v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="resourceTermDateBreakdownDataPrevious" />
                  <bar-chart style="width: 100%;" v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="resourceTermDateBreakdownDataPrevious" />
                </div>
                <div v-if="resourceTermDateBreakdownDataPrevious.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col cols="12">
          <base-material-card icon="mdi-chart-bar" color="info" :title="'Top resource terms searched over ' + dateRangeText">
            <v-row>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Current Period</h2>
                <div v-if="topResourceSearchesData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topResourceSearchesData" />
                </div>
                <div v-if="topResourceSearchesData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="6" class="d-print-inline-flex">
                <h2 class="text-center">Previous Period</h2>
                <div v-if="topResourceSearchesCompareData.labels.length >= 1">
                  <bar-chart style="width: 100%;" v-if="chartloaded" :chart-data="topResourceSearchesCompareData" />
                </div>
                <div v-if="topResourceSearchesCompareData.labels.length === 0">
                  <v-alert border="right" colored-border type="error" elevation="2">
                    <v-row align="center" justify="center">
                      <v-col class="grow text-h3">No metrics found for this range.</v-col>
                    </v-row>
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
    </v-tab-item>
    <v-tab-item id="tab-5">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-map-search" color="info" :title="'Location Insights Over ' + dateRangeText">
            <LocationMapSearch :searchTerms="mapinfo" />
            <v-overlay z-index="99999" absolute :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </base-material-card>
        </v-col>
      </v-row>
    </v-tab-item>
  </v-tabs-items>
  <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
    <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
    {{ snackText }}
  </v-snackbar>
</div>
</template>

<script>
import LocationMapSearch from '../component/LocationMapSearch'
import getSearchMetrics from '@/Services/Analytics/getSearchMetrics'
import getCustomSearchMetrics from '@/Services/Analytics/getCustomSearchMetrics'
import LineChart from '@/views/dashboard/charts/LineChart'
import BarChart from '@/views/dashboard/charts/BarChart'
import listingManagement from '@/Services/Listings/ListingManagement'
import LocationSearch from '@/views/dashboard/component/LocationSearch'

export default {
  name: 'SearchMetrics',
  components: {
    LineChart,
    BarChart,
    LocationMapSearch,
    LocationSearch
  },
  data: () => {
    return {
      overlay: false,
      searchPost: '',
      isLoading: false,
      tagOptions: [],
      selectedTags: [],
      mapinfo: [],
      searchTags: '',
      tab: null,
      selectedFilter: 4,
      menu: false,
      dates: [],
      filterOptions: [{
        value: 1,
        text: 'Today'
      },
      {
        value: 2,
        text: 'Yesterday'
      },
      {
        value: 3,
        text: 'Last 7 Days'
      },
      {
        value: 4,
        text: 'Last 28 Days'
      },
      {
        value: 5,
        text: 'Custom Date Range'
      }
      ],
      colors: [],
      chartloaded: false,
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      listingTermDateBreakdown: [],
      listingTermDateBreakdownPrevious: [],
      resourceTermDateBreakdown: [],
      resourceTermDateBreakdownPrevious: [],
      termDateBreakdown: [],
      termDateBreakdownPrevious: [],
      topListingSearches: [],
      topListingSearchesCompare: [],
      topResourceSearches: [],
      topResourceSearchesCompare: [],
      topTerms: [],
      topTermsCompare: [],
      topVideosSearches: [],
      topVideosSearchesCompare: [],
      videoTermDateBreakdown: [],
      videoTermDateBreakdownPrevious: [],
      termDateBreakdownData: {
        datasets: []
      },
      termDateBreakdownDataPrevious: {
        datasets: []
      },
      listingTermDateBreakdownData: {
        datasets: []
      },
      listingTermDateBreakdownDataPrevious: {
        datasets: []
      },
      resourceTermDateBreakdownData: {
        datasets: []
      },
      resourceTermDateBreakdownDataPrevious: {
        datasets: []
      },
      videoTermDateBreakdownData: {
        datasets: []
      },
      videoTermDateBreakdownDataPrevious: {
        datasets: []
      },
      topTermsData: {
        labels: [],
        datasets: [{
          label: 'Current Period',
          data: [],
          backgroundColor: []
        }]
      },
      topTermsCompareData: {
        labels: [],
        datasets: [{
          label: 'Previous Period',
          data: [],
          backgroundColor: []
        }]
      },
      topListingSearchesData: {
        labels: [],
        datasets: [{
          label: 'Current Period',
          data: [],
          backgroundColor: []
        }]
      },
      topListingSearchesCompareData: {
        labels: [],
        datasets: [{
          label: 'Previous Period',
          data: [],
          backgroundColor: []
        }]
      },
      topResourceSearchesData: {
        labels: [],
        datasets: [{
          label: 'Current Period',
          data: [],
          backgroundColor: []
        }]
      },
      topResourceSearchesCompareData: {
        labels: [],
        datasets: [{
          label: 'Previous Period',
          data: [],
          backgroundColor: []
        }]
      },
      topVideosSearchesData: {
        labels: [],
        datasets: [{
          label: 'Current Period',
          data: [],
          backgroundColor: []
        }]
      },
      topVideosSearchesCompareData: {
        labels: [],
        datasets: [{
          label: 'Previous Period',
          data: [],
          backgroundColor: []
        }]
      }
    }
  },
  mounted () {
    this.initialise(4)
  },
  watch: {
    async searchTags (val) {
      // Items have already been loaded
      if (this.tagOptions.length > 0) return
      // Items have already been requested
      if (this.isLoading) return
      this.isLoading = true
      // Lazily load input items
      await listingManagement.getTags()
        .then((res) => {
          if (res.status === 200) {
            this.tagOptions = res.data
            this.isLoading = false
          } else {
            this.snack = true
            this.snackColor = 'error'
            this.snackText = 'Failed to load tags. Error: ' + res.data.title
            this.snackTimeout = 5000
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err)
        })
    }
  },
  computed: {
    dateRangeText () {
      let dateText
      if (this.selectedFilter === 5) {
        dateText = this.dates.join(' ~ ')
      } else {
        this.filterOptions.forEach(element => {
          if (element.value !== 5 && element.value === this.selectedFilter) {
            dateText = element.text
          }
        })
      }
      return dateText
    }
  },
  methods: {
    async initialise (filter) {
      this.$emit('status', 'loading')
      this.overlay = true
      this.chartloaded = false
      let filterObject = {}
      this.dates = []
      this.resetData()
      filterObject = {
        filterType: filter
      }
      await getSearchMetrics.getSearchMetrics(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.chartloaded = true
            this.overlay = false
            this.$emit('status', 'finished')
          } else {
            this.$emit('status', 'searchError')
          }
        })
    },
    async getPreSetHighlights (value) {
      this.selectedFilter = value
      this.dates = []
      this.resetData()
      let filterObject = {}
      if (this.selectedTags.length === 0) {
        if (!this.searchPost) {
          if (this.selectedFilter !== 5) {
            this.loadSnack()
            filterObject = {
              filterType: this.selectedFilter
            }
            await getSearchMetrics.getSearchMetrics(filterObject)
              .then((response) => {
                this.assignResponse(response)
                this.finishSnack()
              })
          }
        } else if (this.searchPost) {
          if (this.selectedFilter !== 5) {
            this.loadSnack()
            filterObject = {
              filterType: this.selectedFilter,
              districtID: this.searchPost
            }
            await getSearchMetrics.getSearchMetrics(filterObject)
              .then((response) => {
                this.assignResponse(response)
                this.finishSnack()
              })
          }
        }
      } else if (this.selectedTags.length >= 1) {
        if (this.selectedFilter !== 5) {
          if (!this.searchPost) {
            this.loadSnack()
            filterObject = {
              filterType: this.selectedFilter
            }
            await getCustomSearchMetrics.getCustomSearchMetrics(filterObject, this.selectedTags)
              .then((response) => {
                this.assignResponse(response)
                this.finishSnack()
              })
          } else if (this.searchPost) {
            this.loadSnack()
            filterObject = {
              filterType: this.selectedFilter,
              districtID: this.searchPost
            }
            await getCustomSearchMetrics.getCustomSearchMetrics(filterObject, this.selectedTags)
              .then((response) => {
                this.assignResponse(response)
                this.finishSnack()
              })
          }
        }
      }
    },
    async getCustomDateHighlights () {
      this.loadSnack()
      this.$refs.menu.save(this.dates)
      this.menu = false
      this.resetData()
      let filterObject = {}
      if (this.selectedTags.length === 0) {
        if (this.selectedFilter === 5) {
          this.dates.sort(function (a, b) {
            a = a.split('/').reverse().join('')
            b = b.split('/').reverse().join('')
            return a > b ? 1 : a < b ? -1 : 0
          })
          const startDate = this.dates[0]
          const endDate = this.dates[1]
          if (!this.searchPost) {
            if (startDate && endDate) {
              filterObject = {
                filterType: this.selectedFilter,
                startDate: startDate,
                endDate: endDate
              }
              await getSearchMetrics.getSearchMetrics(filterObject)
                .then((response) => {
                  this.assignResponse(response)
                  this.finishSnack()
                })
            } else {
              this.errorSnack()
            }
          } else if (this.searchPost) {
            if (startDate && endDate) {
              filterObject = {
                filterType: this.selectedFilter,
                startDate: startDate,
                endDate: endDate,
                districtID: this.searchPost
              }
              await getSearchMetrics.getSearchMetrics(filterObject)
                .then((response) => {
                  this.assignResponse(response)
                  this.finishSnack()
                })
            } else {
              this.errorSnack()
            }
          }
        }
      } else if (this.selectedTags.length >= 1) {
        if (this.selectedFilter === 5) {
          this.dates.sort(function (a, b) {
            a = a.split('/').reverse().join('')
            b = b.split('/').reverse().join('')
            return a > b ? 1 : a < b ? -1 : 0
          })
          const startDate = this.dates[0]
          const endDate = this.dates[1]
          if (!this.searchPost) {
            if (startDate && endDate) {
              filterObject = {
                filterType: this.selectedFilter,
                startDate: startDate,
                endDate: endDate
              }
              await getCustomSearchMetrics.getCustomSearchMetrics(filterObject, this.selectedTags)
                .then((response) => {
                  this.assignResponse(response)
                  this.finishSnack()
                })
            } else {
              this.errorSnack()
            }
          } else if (this.searchPost) {
            if (startDate && endDate) {
              filterObject = {
                filterType: this.selectedFilter,
                startDate: startDate,
                endDate: endDate,
                districtID: this.searchPost
              }
              await getCustomSearchMetrics.getCustomSearchMetrics(filterObject, this.selectedTags)
                .then((response) => {
                  this.assignResponse(response)
                  this.finishSnack()
                })
            } else {
              this.errorSnack()
            }
          }
        }
      }
    },
    getCustomResults () {
      if (this.selectedFilter === 5) {
        this.getCustomDateHighlights()
      } else if (this.selectedFilter !== 5) {
        this.getPreSetHighlights(this.selectedFilter)
      }
    },
    getLocation (location) {
      this.searchPost = location
      this.getCustomResults()
    },
    removeLocation () {
      this.searchPost = null
      this.getCustomResults()
    },
    assignResponse (response) {
      this.$set(this, 'mapinfo', response.data.map.outcodeAreas)
      if (response.data.listingTermDateBreakdown !== null) {
        response.data.listingTermDateBreakdown.forEach(element => {
          this.listingTermDateBreakdown.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.listingTermDateBreakdownData.datasets.push(dataset)
          this.listingTermDateBreakdownData.labels = labels
        })
      }
      if (response.data.listingTermDateBreakdownPrevious !== null) {
        response.data.listingTermDateBreakdownPrevious.forEach(element => {
          this.listingTermDateBreakdownPrevious.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.listingTermDateBreakdownDataPrevious.datasets.push(dataset)
          this.listingTermDateBreakdownDataPrevious.labels = labels
        })
      }
      if (response.data.resourceTermDateBreakdown !== null) {
        response.data.resourceTermDateBreakdown.forEach(element => {
          this.resourceTermDateBreakdown.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.resourceTermDateBreakdownData.datasets.push(dataset)
          this.resourceTermDateBreakdownData.labels = labels
        })
      }
      if (response.data.resourceTermDateBreakdownPrevious !== null) {
        response.data.resourceTermDateBreakdownPrevious.forEach(element => {
          this.resourceTermDateBreakdownPrevious.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.resourceTermDateBreakdownDataPrevious.datasets.push(dataset)
          this.resourceTermDateBreakdownDataPrevious.labels = labels
        })
      }
      if (response.data.termDateBreakdown !== null) {
        response.data.termDateBreakdown.forEach(element => {
          this.termDateBreakdown.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.termDateBreakdownData.datasets.push(dataset)
          this.termDateBreakdownData.labels = labels
        })
      }
      if (response.data.termDateBreakdownPrevious !== null) {
        response.data.termDateBreakdownPrevious.forEach(element => {
          this.termDateBreakdownPrevious.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.termDateBreakdownDataPrevious.datasets.push(dataset)
          this.termDateBreakdownDataPrevious.labels = labels
        })
      }
      if (response.data.topListingSearches !== null) {
        response.data.topListingSearches.forEach(element => {
          this.topListingSearches.push(element)
          const color = this.random_rgba()
          this.topListingSearchesData.labels.push(element.term)
          this.topListingSearchesData.datasets[0].data.push(element.numberOfSearches)
          this.topListingSearchesData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topListingSearchesCompare !== null) {
        response.data.topListingSearchesCompare.forEach(element => {
          this.topListingSearchesCompare.push(element)
          const color = this.random_rgba()
          this.topListingSearchesCompareData.labels.push(element.term)
          this.topListingSearchesCompareData.datasets[0].data.push(element.numberOfSearches)
          this.topListingSearchesCompareData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topResourceSearches !== null) {
        response.data.topResourceSearches.forEach(element => {
          this.topResourceSearches.push(element)
          const color = this.random_rgba()
          this.topResourceSearchesData.labels.push(element.term)
          this.topResourceSearchesData.datasets[0].data.push(element.numberOfSearches)
          this.topResourceSearchesData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topResourceSearchesCompare !== null) {
        response.data.topResourceSearchesCompare.forEach(element => {
          this.topResourceSearchesCompare.push(element)
          const color = this.random_rgba()
          this.topResourceSearchesCompareData.labels.push(element.term)
          this.topResourceSearchesCompareData.datasets[0].data.push(element.numberOfSearches)
          this.topResourceSearchesCompareData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topTerms !== null) {
        response.data.topTerms.forEach(element => {
          const color = this.random_rgba()
          this.topTerms.push(element)
          this.topTermsData.labels.push(element.term)
          this.topTermsData.datasets[0].data.push(element.numberOfSearches)
          this.topTermsData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topTermsCompare !== null) {
        response.data.topTermsCompare.forEach(element => {
          this.topTermsCompare.push(element)
          const color = this.random_rgba()
          this.topTermsCompareData.labels.push(element.term)
          this.topTermsCompareData.datasets[0].data.push(element.numberOfSearches)
          this.topTermsCompareData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topVideosSearches !== null) {
        response.data.topVideosSearches.forEach(element => {
          this.topVideosSearches.push(element)
          const color = this.random_rgba()
          this.topVideosSearchesData.labels.push(element.term)
          this.topVideosSearchesData.datasets[0].data.push(element.numberOfSearches)
          this.topVideosSearchesData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.topVideosSearchesCompare !== null) {
        response.data.topVideosSearchesCompare.forEach(element => {
          this.topVideosSearchesCompare.push(element)
          const color = this.random_rgba()
          this.topVideosSearchesCompareData.labels.push(element.term)
          this.topVideosSearchesCompareData.datasets[0].data.push(element.numberOfSearches)
          this.topVideosSearchesCompareData.datasets[0].backgroundColor.push(color)
        })
      }
      if (response.data.videoTermDateBreakdown !== null) {
        response.data.videoTermDateBreakdown.forEach(element => {
          this.videoTermDateBreakdown.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.videoTermDateBreakdownData.datasets.push(dataset)
          this.videoTermDateBreakdownData.labels = labels
        })
      }
      if (response.data.videoTermDateBreakdownPrevious !== null) {
        response.data.videoTermDateBreakdownPrevious.forEach(element => {
          this.videoTermDateBreakdownPrevious.push(element)
          const color = this.random_rgba()
          const dataset = {
            label: element.term,
            data: [],
            fill: false,
            borderColor: color,
            backgroundColor: color
          }
          const labels = []
          element.breakdownByDate.forEach(e => {
            dataset.data.push(e.searches)
            labels.push(e.date)
          })
          this.videoTermDateBreakdownDataPrevious.datasets.push(dataset)
          this.videoTermDateBreakdownDataPrevious.labels = labels
        })
      }
    },
    resetData () {
      this.termDateBreakdownData = {
        datasets: [],
        labels: []
      }
      this.termDateBreakdownDataPrevious = {
        datasets: [],
        labels: []
      }
      this.listingTermDateBreakdownData = {
        datasets: [],
        labels: []
      }
      this.listingTermDateBreakdownDataPrevious = {
        datasets: [],
        labels: []
      }
      this.resourceTermDateBreakdownData = {
        datasets: [],
        labels: []
      }
      this.resourceTermDateBreakdownDataPrevious = {
        datasets: [],
        labels: []
      }
      this.videoTermDateBreakdownData = {
        datasets: [],
        labels: []
      }
      this.videoTermDateBreakdownDataPrevious = {
        datasets: [],
        labels: []
      }
      this.topTermsData.labels = []
      this.topTermsData.datasets = [{
        label: 'Current Period',
        data: [],
        backgroundColor: []
      }]
      this.topTermsCompareData.labels = []
      this.topTermsCompareData.datasets = [{
        label: 'Previous Period',
        data: [],
        backgroundColor: []
      }]
      this.topListingSearchesData.labels = []
      this.topListingSearchesData.datasets = [{
        label: 'Current Period',
        data: [],
        backgroundColor: []
      }]
      this.topListingSearchesCompareData.labels = []
      this.topListingSearchesCompareData.datasets = [{
        label: 'Previous Period',
        data: [],
        backgroundColor: []
      }]
      this.topResourceSearchesData.labels = []
      this.topResourceSearchesData.datasets = [{
        label: 'Current Period',
        data: [],
        backgroundColor: []
      }]
      this.topResourceSearchesCompareData.labels = []
      this.topResourceSearchesCompareData.datasets = [{
        label: 'Previous Period',
        data: [],
        backgroundColor: []
      }]
      this.topVideosSearchesData.labels = []
      this.topVideosSearchesData.datasets = [{
        label: 'Current Period',
        data: [],
        backgroundColor: []
      }]
      this.topVideosSearchesCompareData.labels = []
      this.topVideosSearchesCompareData.datasets = [{
        label: 'Previous Period',
        data: [],
        backgroundColor: []
      }]
    },
    loadSnack () {
      this.overlay = true
      this.chartloaded = false
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
    },
    finishSnack () {
      this.overlay = false
      this.snackColor = 'success'
      this.snackText = 'Done'
      this.snackTimeout = 3000
      this.chartloaded = true
    },
    errorSnack () {
      this.snackColor = 'error'
      this.snackText = 'Error'
      this.snackTimeout = 3000
    },
    random_rgba () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')
    },
    convertTime (timestamp) {
      const hours = Math.floor(timestamp / 60 / 60)
      const minutes = Math.floor(timestamp / 60) - (hours * 60)
      const seconds = Math.round(timestamp % 60)
      const formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
      return formatted
    },
    removeTag (item) {
      const index = this.selectedTags.findIndex(i => i.tagName === item.tagName)
      if (index >= 0) this.selectedTags.splice(index, 1)
    }
  }
}
</script>
