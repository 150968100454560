<template>
<div>
  <v-row>
    <v-col cols="6">
      <div class="text-center">
        <v-menu v-if="selectedFilter === 5" ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates" transition="scale-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Select a Range
            </v-btn>
          </template>
          <v-date-picker v-model="dates" scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn :disabled="dates.length <= 1" text color="primary" @click="getCustomDateHighlights">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </v-col>
    <v-col cols="12" sm="6" md="6">
      <v-menu>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="float-right">
                Date Range
                <v-icon right dark>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <span>Select a date range</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in filterOptions" :key="index" @click="getPreSetHighlights(item.value)">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <base-material-card color="info" icon="mdi-google-analytics" :title="'Portal Analytics - ' + dateRangeText">
        <v-toolbar flat>
          <v-tabs v-model="tab" centered grow>
            <v-tab>
              Page Views
              <v-icon class="ml-2">mdi-monitor-eye</v-icon>
            </v-tab>

            <v-tab>
              Users
              <v-icon class="ml-2">mdi-account-multiple</v-icon>
            </v-tab>

            <v-tab>
              Sessions
              <v-icon class="ml-2">mdi-web</v-icon>
            </v-tab>
            <v-tab>
              Bounce Rate
              <v-icon class="ml-2">mdi-call-missed</v-icon>
            </v-tab>

            <v-tab>
              Avg Session Duration
              <v-icon class="ml-2">mdi-timer</v-icon>
            </v-tab>
          </v-tabs>
        </v-toolbar>
        <v-row v-if="chartloaded">
          <v-col cols="12">
            <v-tabs-items v-if="chartloaded" v-model="tab">
              <v-tab-item>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Page Views - {{dateRangeText}} - Total: {{googleHighlights.pageViews}}</v-card-title>
                    <line-chart v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="pageViewsData" />
                    <bar-chart v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="pageViewsData" />
                    <v-card-text>
                      <p v-if="!googleHighlights.pageViewsDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="green" small>
                          mdi-arrow-up
                        </v-icon>
                        <span class="green--text"><strong>{{googleHighlights.pageViewsDifference}}</strong></span>&nbsp;
                        up from the previous period
                      </p>

                      <p v-if="googleHighlights.pageViewsDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="red" small>
                          mdi-arrow-down
                        </v-icon>
                        <span class="red--text"><strong>{{googleHighlights.pageViewsDifference}}</strong></span>&nbsp;
                        down from the previous period
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Users - {{dateRangeText}} - Total: {{googleHighlights.users}}</v-card-title>
                    <line-chart v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="usersData" />
                    <bar-chart v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="usersData" />
                    <v-card-text>
                      <p v-if="!googleHighlights.userDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="green" small>
                          mdi-arrow-up
                        </v-icon>
                        <span class="green--text"><strong>{{googleHighlights.userDifference}}</strong></span>&nbsp;
                        up from the previous period
                      </p>

                      <p v-if="googleHighlights.userDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="red" small>
                          mdi-arrow-down
                        </v-icon>
                        <span class="red--text"><strong>{{googleHighlights.userDifference}}</strong></span>&nbsp;
                        down from the previous period
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Sessions - {{dateRangeText}} - Total: {{googleHighlights.sessions}}</v-card-title>
                    <line-chart v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="sessionsData" />
                    <bar-chart v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="sessionsData" />
                    <v-card-text>
                      <p v-if="!googleHighlights.sessionsDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="green" small>
                          mdi-arrow-up
                        </v-icon>
                        <span class="green--text"><strong>{{googleHighlights.sessionsDifference}}</strong></span>&nbsp;
                        up from the previous period
                      </p>

                      <p v-if="googleHighlights.sessionsDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="red" small>
                          mdi-arrow-down
                        </v-icon>
                        <span class="red--text"><strong>{{googleHighlights.sessionsDifference}}</strong></span>&nbsp;
                        down from the previous period
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Bounce Rate - {{dateRangeText}} - Avg: {{bounceRateText}}</v-card-title>
                    <line-chart v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="bounceData" />
                    <bar-chart v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="bounceData" />
                    <v-card-text>
                      <p v-if="!googleHighlights.bounceRateDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="red" small>
                          mdi-arrow-up
                        </v-icon>
                        <span class="red--text">{{googleHighlights.bounceRateDifference}}</span>&nbsp;
                        up from the previous period
                      </p>

                      <p v-if="googleHighlights.bounceRateDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="green" small>
                          mdi-arrow-down
                        </v-icon>
                        <span class="green--text">{{googleHighlights.bounceRateDifference}}</span>&nbsp;
                        down from the previous period
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Avg Session Duration - {{dateRangeText}}: {{durationText}}</v-card-title>
                    <time-chart v-if="chartloaded && (selectedFilter !== 1 && selectedFilter !== 2)" :chart-data="avgSessionData" />
                    <time-bar v-if="chartloaded && (selectedFilter === 1 || selectedFilter === 2)" :chart-data="avgSessionData" />
                    <v-card-text>
                      <p v-if="!googleHighlights.durationDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="green" small>
                          mdi-arrow-up
                        </v-icon>
                        <span class="green--text"><strong>{{googleHighlights.durationDifference}}</strong></span>&nbsp;
                        up from the previous period
                      </p>

                      <p v-if="googleHighlights.durationDifference.includes('-')" class="d-inline-flex font-weight-light ml-2 mt-1">
                        <v-icon color="red" small>
                          mdi-arrow-down
                        </v-icon>
                        <span class="red--text"><strong>{{googleHighlights.durationDifference}}</strong></span>&nbsp;
                        down from the previous period
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </base-material-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <base-material-card icon="mdi-google-analytics" :title="'User Metrics - ' + dateRangeText">
        <v-row>
          <v-col cols="12" md="6" class="mt-10">
            <base-material-card color="indigo" icon="mdi-folder" inline class="d-print-inline-block" v-if="googleHighlights.returnVisitorMetrics !== null">
              <template v-slot:after-heading>
                <div class="display-2 font-weight-light">
                  Current Period: {{dateRangeText}}
                </div>
              </template>
              <v-row>
                <v-col cols="12">
                  <v-card flat>
                    <v-card-title>Total: {{googleHighlights.users}}</v-card-title>
                    <v-card-text class="text-center">
                      <v-row>
                        <v-col cols="6">
                          <p class="display-1">
                            {{googleHighlights.returnVisitorMetrics.newVisitors}} - New Users
                          </p>
                          <p>{{googleHighlights.returnVisitorMetrics.newVisitorPercentage}} of total users are new</p>
                        </v-col>
                        <v-col cols="6">
                          <p class="display-1">
                            {{googleHighlights.returnVisitorMetrics.returningVisitors}} - Returning Users
                          </p>
                          <p>{{googleHighlights.returnVisitorMetrics.returningVisitorPercentage}} of total users are returning to the site</p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <doughnut-chart v-if="chartloaded" :chart-data="returningUserData" />
            </base-material-card>
          </v-col>
          <v-col cols="12" md="6" class="mt-10">
            <base-material-card v-if="googleHighlights.returnVisitorMetricsPrevious !== null" color="indigo" icon="mdi-chart-pie" inline class="d-print-inline-block">
              <template v-slot:after-heading>
                <div class="display-2 font-weight-light">
                  Previous Period
                </div>
              </template>
              <v-row>
                <v-col cols="12">
                  <v-card flat>
                    <v-card-title>Total: {{googleHighlights.userPrevious}}</v-card-title>
                    <v-card-text class="text-center">
                      <v-row>
                        <v-col cols="6">
                          <p class="display-1">
                            {{googleHighlights.returnVisitorMetricsPrevious.newVisitors}} - New Users
                          </p>
                          <p>{{googleHighlights.returnVisitorMetricsPrevious.newVisitorPercentage}} of total users are new</p>
                        </v-col>
                        <v-col cols="6">
                          <p class="display-1">
                            {{googleHighlights.returnVisitorMetricsPrevious.returningVisitors}} - Returning Users
                          </p>
                          <p>{{googleHighlights.returnVisitorMetricsPrevious.returningVisitorPercentage}} of total users are returning to the site</p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <doughnut-chart v-if="chartloaded" :chart-data="returningUserPrevData" />
            </base-material-card>
          </v-col>
        </v-row>
      </base-material-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <base-material-card icon="mdi-star" :title="'Top Listings/Videos - ' + dateRangeText">
        <v-row>
          <v-col cols="12" class="mt-10">
            <base-material-card color="indigo" icon="mdi-clipboard" inline class="px-5 py-3">
              <template v-slot:after-heading>
                <div class="display-2 font-weight-light">
                  Top 5 Listings
                </div>
              </template>
              <v-data-table :headers="headers" :items="topListings" :disable-pagination="true" hide-default-footer>
                <template v-slot:item.view="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon class="mr-2 v-btn--flat" v-bind="attrs" v-on="on" color="teal" target="_blank" :href="item.url">
                        <v-icon>
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>View Listing</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </base-material-card>
          </v-col>
          <v-col cols="12" class="mt-10">
            <base-material-card color="indigo" icon="mdi-video-vintage" inline class="px-5 py-3">
              <template v-slot:after-heading>
                <div class="display-2 font-weight-light">
                  Top 5 Videos
                </div>
              </template>
              <v-data-table :headers="headers" :items="topVideos" :disable-pagination="true" hide-default-footer>
                <template v-slot:item.view="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon class="mr-2 v-btn--flat" v-bind="attrs" v-on="on" color="teal" target="_blank" :href="item.url">
                        <v-icon>
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>View Video</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </base-material-card>
          </v-col>
          <v-col cols="12" class="mt-10">
            <base-material-card color="indigo" icon="mdi-folder" inline class="px-5 py-3">
              <template v-slot:after-heading>
                <div class="display-2 font-weight-light">
                  Top 5 Resources
                </div>
              </template>
              <v-data-table :headers="resourceHeaders" :items="topResources" :disable-pagination="true" hide-default-footer />
            </base-material-card>
          </v-col>
        </v-row>
      </base-material-card>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <base-material-card icon="mdi-star" :title="'Top Terms - ' + dateRangeText" class="d-print-inline-block">
        <v-row>
          <v-col cols="12" md="6" class="mt-10">
            <v-simple-table class="ml-2">
              <thead>
                <tr>
                  <th class="text-left">
                    Term
                  </th>
                  <th class="text-left">
                    No. of Searches
                  </th>
                  <th class="text-left">
                    Overall Contribution
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(term, i) in topTerms" :key="i">
                  <td v-text="term.term" />
                  <td v-text="term.numberOfSearches" />
                  <td v-text="term.overallContribution" />
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="6">
            <doughnut-chart v-if="chartloaded" :chart-data="termData" />
          </v-col>
        </v-row>
      </base-material-card>
    </v-col>
  </v-row>
  <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
    <v-progress-circular v-if="snackColor === 'info'" color="white" indeterminate></v-progress-circular>
    {{ snackText }}
  </v-snackbar>
</div>
</template>

<script>
import getHighlights from '@/Services/Analytics/getHighlights.vue'
import LineChart from '@/views/dashboard/charts/LineChart'
import TimeChart from '@/views/dashboard/charts/TimeLineChart'
import DoughnutChart from '@/views/dashboard/charts/DoughnutChart'
import BarChart from '@/views/dashboard/charts/BarChart'
import TimeBar from '@/views/dashboard/charts/TimeBarChart'

export default {
  name: 'Highlights',
  components: {
    LineChart,
    TimeChart,
    DoughnutChart,
    BarChart,
    TimeBar
  },
  data: () => {
    return {
      tab: null,
      colors: [],
      chartloaded: false,
      snack: false,
      snackColor: '',
      snackText: '',
      snackTimeout: null,
      loading: false,
      selectedFilter: 4,
      filterOptions: [{
        value: 1,
        text: 'Today'
      },
      {
        value: 2,
        text: 'Yesterday'
      },
      {
        value: 3,
        text: 'Last 7 Days'
      },
      {
        value: 4,
        text: 'Last 28 Days'
      },
      {
        value: 5,
        text: 'Custom Date Range'
      }
      ],
      headers: [{
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'uniqueID'
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'View',
        value: 'view',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category',
        sortable: false
      },
      {
        text: 'Number of Views',
        value: 'numberofViews',
        sortable: false
      },
      {
        text: 'Published Date',
        value: 'publishedDate',
        sortable: false
      }
      ],
      resourceHeaders: [{
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'uniqueID'
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Category',
        value: 'category',
        sortable: false
      },
      {
        text: 'Number of Views',
        value: 'numberofViews',
        sortable: false
      },
      {
        text: 'Published Date',
        value: 'publishedDate',
        sortable: false
      }
      ],
      menu: false,
      dates: [],
      topListings: [],
      topVideos: [],
      topResources: [],
      topTerms: [],
      googleHighlights: {},
      startDate: null,
      endDate: null,
      termData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [],
          borderColor: [],
          data: []
        }]
      },
      returningUserData: {
        labels: ['New Users', 'Returning Users'],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [],
          borderColor: [],
          data: []
        }]
      },
      returningUserPrevData: {
        labels: ['New Users', 'Returning Users'],
        datasets: [{
          borderWidth: 1,
          backgroundColor: [],
          borderColor: [],
          data: []
        }]
      },
      usersData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      pageViewsData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      sessionsData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      bounceData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      },
      avgSessionData: {
        datasets: [{
          label: 'Current Period',
          data: [],
          fill: false,
          borderColor: 'rgba(0, 200, 255, 1)',
          backgroundColor: 'rgba(0, 200, 255, 0.5)'
        },
        {
          label: 'Previous Period',
          data: [],
          fill: false,
          borderColor: 'rgba(255,160,0,1)',
          backgroundColor: 'rgba(255,160,0,0.5)'
        }
        ]
      }
    }
  },
  mounted () {
    this.initialise(4)
  },
  computed: {
    durationText () {
      return this.convertTime(this.googleHighlights.avgSessionDuration)
    },
    dateRangeText () {
      let dateText
      if (this.selectedFilter === 5) {
        dateText = this.dates.join(' ~ ')
      } else {
        this.filterOptions.forEach(element => {
          if (element.value !== 5 && element.value === this.selectedFilter) {
            dateText = element.text
          }
        })
      }
      return dateText
    },
    bounceRateText () {
      return this.googleHighlights.bounceRate + '%'
    }
  },
  methods: {
    async initialise (filter) {
      this.$emit('status', 'loading')
      this.chartloaded = false
      let filterObject = {}
      this.dates = []
      this.resetData()
      filterObject = {
        filterType: filter
      }
      await getHighlights.getHighlights(filterObject)
        .then((response) => {
          if (response.status === 200) {
            this.assignResponse(response)
            this.chartloaded = true
            this.$emit('status', 'finished')
          } else {
            this.$emit('status', 'highlightsError')
          }
        })
    },
    async getPreSetHighlights (value) {
      this.selectedFilter = value
      this.dates = []
      this.resetData()
      let filterObject = {}
      if (this.selectedFilter !== 5) {
        this.loadSnack()
        filterObject = {
          filterType: this.selectedFilter
        }
        await getHighlights.getHighlights(filterObject)
          .then((response) => {
            this.assignResponse(response)
            this.finishSnack()
          })
      }
    },
    async getCustomDateHighlights () {
      this.loadSnack()
      this.$refs.menu.save(this.dates)
      this.menu = false
      this.resetData()
      let filterObject = {}
      if (this.selectedFilter === 5) {
        this.dates.sort(function (a, b) {
          a = a.split('/').reverse().join('')
          b = b.split('/').reverse().join('')
          return a > b ? 1 : a < b ? -1 : 0
        })
        const startDate = this.dates[0]
        const endDate = this.dates[1]
        if (startDate && endDate) {
          filterObject = {
            filterType: this.selectedFilter,
            startDate: startDate,
            endDate: endDate
          }
          await getHighlights.getHighlights(filterObject)
            .then((response) => {
              this.assignResponse(response)
              this.finishSnack()
            })
        } else {
          this.errorSnack()
        }
      }
    },
    assignResponse (response) {
      this.$set(this, 'topListings', response.data.topListings)
      this.$set(this, 'topVideos', response.data.topVideos)
      this.$set(this, 'topResources', response.data.topResources)
      this.$set(this, 'topTerms', response.data.topTerms)
      this.topTerms.forEach(element => {
        const color = this.random_rgba()
        if (this.colors.includes(color)) {
          const backupColor = this.random_rgba()
          this.colors.push(backupColor)
          this.termData.datasets[0].backgroundColor.push(backupColor)
          this.termData.datasets[0].borderColor.push(backupColor)
        } else {
          this.colors.push(color)
          this.termData.datasets[0].backgroundColor.push(color)
          this.termData.datasets[0].borderColor.push(color)
        }
        this.termData.labels.push(element.term)
        this.termData.datasets[0].data.push(element.numberOfSearches)
      })
      this.$set(this, 'googleHighlights', response.data.googleHighlights)
      response.data.breakdownByDate.forEach(element => {
        this.usersData.datasets[0].data.push({
          x: element.date,
          y: element.users
        })
        this.usersData.labels.push(element.date)
        this.pageViewsData.datasets[0].data.push({
          x: element.date,
          y: element.pageViews
        })
        this.pageViewsData.labels.push(element.date)
        this.sessionsData.datasets[0].data.push({
          x: element.date,
          y: element.sessions
        })
        this.sessionsData.labels.push(element.date)
        this.bounceData.datasets[0].data.push({
          x: element.date,
          y: element.bounceRate
        })
        this.bounceData.labels.push(element.date)
        this.avgSessionData.datasets[0].data.push({
          x: element.date,
          y: element.avgSessionDuration
        })
        this.avgSessionData.labels.push(element.date)
      })
      response.data.breakdownByDatePrevious.forEach(element => {
        this.usersData.datasets[1].data.push({
          x: element.date,
          y: element.users
        })
        this.pageViewsData.datasets[1].data.push({
          x: element.date,
          y: element.pageViews
        })
        this.sessionsData.datasets[1].data.push({
          x: element.date,
          y: element.sessions
        })
        this.bounceData.datasets[1].data.push({
          x: element.date,
          y: element.bounceRate
        })
        this.avgSessionData.datasets[1].data.push({
          x: element.date,
          y: element.avgSessionDuration
        })
      })
      if (this.googleHighlights.returnVisitorMetrics !== null) {
        this.returningUserData.datasets[0].data.push(this.googleHighlights.returnVisitorMetrics.newVisitors)
        this.returningUserData.datasets[0].data.push(this.googleHighlights.returnVisitorMetrics.returningVisitors)
        const color1 = this.colors[0]
        const color2 = this.colors[1]
        this.returningUserData.datasets[0].backgroundColor.push(color1)
        this.returningUserData.datasets[0].borderColor.push(color1)
        this.returningUserData.datasets[0].backgroundColor.push(color2)
        this.returningUserData.datasets[0].borderColor.push(color2)
      }
      if (this.googleHighlights.returnVisitorMetricsPrevious !== null) {
        const color1 = this.colors[0]
        const color2 = this.colors[1]
        this.returningUserPrevData.datasets[0].data.push(this.googleHighlights.returnVisitorMetricsPrevious.newVisitors)
        this.returningUserPrevData.datasets[0].data.push(this.googleHighlights.returnVisitorMetricsPrevious.returningVisitors)
        this.returningUserPrevData.datasets[0].backgroundColor.push(color1)
        this.returningUserPrevData.datasets[0].borderColor.push(color1)
        this.returningUserPrevData.datasets[0].backgroundColor.push(color2)
        this.returningUserPrevData.datasets[0].borderColor.push(color2)
      }
    },
    resetData () {
      this.termData.labels = []
      this.termData.datasets[0].data = []
      this.termData.datasets[0].backgroundColor = []
      this.termData.datasets[0].borderColor = []
      this.returningUserData.datasets[0].data = []
      this.returningUserData.datasets[0].backgroundColor = []
      this.returningUserData.datasets[0].borderColor = []
      this.returningUserPrevData.datasets[0].data = []
      this.returningUserPrevData.datasets[0].backgroundColor = []
      this.returningUserPrevData.datasets[0].borderColor = []
      this.usersData.labels = []
      this.usersData.datasets.forEach(element => {
        element.data = []
      })
      this.pageViewsData.labels = []
      this.pageViewsData.datasets.forEach(element => {
        element.data = []
      })
      this.sessionsData.labels = []
      this.sessionsData.datasets.forEach(element => {
        element.data = []
      })
      this.bounceData.labels = []
      this.bounceData.datasets.forEach(element => {
        element.data = []
      })
      this.avgSessionData.labels = []
      this.avgSessionData.datasets.forEach(element => {
        element.data = []
      })
    },
    loadSnack () {
      this.chartloaded = false
      this.snack = true
      this.snackColor = 'info'
      this.snackText = 'Loading...'
      this.snackTimeout = -1
    },
    finishSnack () {
      this.snackColor = 'success'
      this.snackText = 'Done'
      this.snackTimeout = 3000
      this.chartloaded = true
    },
    errorSnack () {
      this.snackColor = 'error'
      this.snackText = 'Error'
      this.snackTimeout = 3000
    },
    random_rgba () {
      return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')
    },
    convertTime (timestamp) {
      const hours = Math.floor(timestamp / 60 / 60)
      const minutes = Math.floor(timestamp / 60) - (hours * 60)
      const seconds = Math.round(timestamp % 60)
      const formatted = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
      return formatted
    }
  }
}
</script>
