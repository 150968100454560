<template>
<div>
  <v-container>
    <LocationMap @dateFilter="getCustomDateData" @searchLocation="searchLocation" @removeLocation="removeLocation" :categories="categories" :listings="listings"/>
    <v-overlay z-index="99999" absolute :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</div>
</template>

<script>
import mapData from '@/Services/Analytics/mapData'
import LocationMap from '../component/LocationMap'

export default {
  name: 'Location',
  components: {
    LocationMap
  },
  data () {
    return {
      listings: [],
      categories: [],
      overlay: false,
      filterObject: {},
      searchLocVar: ''
    }
  },
  methods: {
    async initialise (filter) {
      this.overlay = true
      this.$emit('status', 'loading')
      let filterObject = {}
      filterObject = {
        filterType: filter
      }
      this.filterObject = filterObject
      await mapData.mapData(filterObject)
        .then((response) => {
          this.assignResponse(response)
          this.$emit('status', 'finished')
          this.overlay = false
        })
    },
    async getCustomDateData (filter) {
      this.filterObject = filter
      this.overlay = true
      if (!this.searchLocVar || this.searchLocVar === null) {
        await mapData.mapData(this.filterObject)
          .then((response) => {
            this.assignResponse(response)
            this.overlay = false
          })
      } else {
        this.filterObject.districtID = this.searchLocVar
        await mapData.searchMap(this.filterObject)
          .then((response) => {
            this.assignResponse(response)
            this.overlay = false
          })
      }
    },
    async searchLocation (location) {
      this.overlay = true
      this.searchLocVar = location
      if (this.searchLocVar) {
        this.filterObject.districtID = this.searchLocVar
        await mapData.searchMap(this.filterObject)
          .then((res) => {
            if (res.status === 200) {
              this.assignResponse(res)
              this.overlay = false
            } else {
              this.overlay = false
              this.$emit('status', 'error')
            }
          })
      } else {
        this.getCustomDateData(this.filterObject)
      }
    },
    removeLocation () {
      var loc = null
      delete this.filterObject.districtID
      this.searchLocVar = null
      this.searchLocation(loc)
    },
    assignResponse (response) {
      this.$set(this, 'listings', response.data.listings)
      this.$set(this, 'categories', response.data.categories)
    }
  },
  mounted () {
    this.initialise(4)
  }
}
</script>
